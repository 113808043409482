import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { period } from "gatsby-component-lib"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import OLayoutMatch from "gatsby-component-lib/components-legacy/organisms/o-layout_match"
import OLiveTickerTeaserBlock from "gatsby-component-lib/components-legacy/organisms/o-live-ticker-teaser-block"
import OMatchHeader from "gatsby-component-lib/components-legacy/organisms/o-match-header"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import { LiveMatchContext } from "gatsby-component-lib/context/LiveMatchContext"
import React, { useContext } from "react"

export const query = graphql`
  query MatchLiveQuery($match_uid: String, $uid: String) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    match: allTypo3Page(
      filter: { uid: { eq: $match_uid }, type: { in: "match" } }
    ) {
      edges {
        node {
          id
          page_uid
          name
          type
          website
          content
          full_slug
          title
          data {
            liveticker_active
            period
            attendance
            city
            definite
            id
            kickoff
            kickoff_timezone
            matchday
            referees {
              last_name
              first_name
              role
              birthday
            }
            team_away {
              id
              team_name
              team_initials_name
              formation_used
              images {
                liveticker
                liveticker_retina
                medium_square
                micro_square
                mini_square
                original
              }
              lineup {
                status
                shirt_number
                role_sub
                role
                last_name
                formation_position
                formation_place
                first_name
                birthplace
                birthday
              }
              manager {
                first_name
                id
                last_name
              }
            }
            team_home {
              id
              team_name
              team_initials_name
              formation_used
              images {
                liveticker
                liveticker_retina
                medium_square
                micro_square
                mini_square
                original
              }
              lineup {
                status
                shirt_number
                role_sub
                role
                last_name
                formation_position
                formation_place
                first_name
                birthplace
                birthday
              }
              manager {
                first_name
                id
                last_name
              }
            }
            team_away_half_score
            team_away_ninety_score
            team_away_penalty_score
            team_away_extra_score
            team_away_final_score
            team_home_half_score
            team_home_ninety_score
            team_home_penalty_score
            team_home_extra_score
            team_home_final_score
            venue
            match_uid
            competition {
              id
              slug
              title
            }
            season {
              id
              slug
              title
            }
            attendance
            kickoff
            kickoff_timezone
            period
            opta_events {
              id
              assist_person_id
              assist_person_name
              event_type
              person_id
              period
              person_name
              person_name_off
              person_name_on
              person_off_id
              person_on_id
              team
              team_name
              time
            }
            ticker_events {
              phase
              event_type
              time
              time_additional
              description
              html_description
              image_src
            }
          }
        }
      }
    }
  }
`

const MatchLiveTickerPage = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    website_lang,
    website_theme,
    footerPageNodeContent,
    subnavigation,
  } = pageContext
  const content = JSON.parse(data.page.content)
  const { t } = useTranslation()

  const matchNode = data.match.edges[0] ? data.match.edges[0].node : {}
  let matchData = matchNode ? matchNode.data : {}

  const matchContext = useContext(LiveMatchContext)

  if (
    matchContext &&
    matchContext.liveMatchData &&
    matchContext.liveMatchData.liveticker_active &&
    parseInt(matchData.id) === parseInt(matchContext.matchId)
  ) {
    matchData = matchContext.liveMatchData
  }

  if (!matchData) {
    return <>Keine Daten vorhanden</>
  }

  const second_breadcrumb = ["profis", "en"].includes(website)
    ? {
        name: t("Season"),
        full_slug: "/" + t("SeasonSlug"),
      }
    : content.parents.reverse()[1]

  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    second_breadcrumb,
  ]

  let title =
    content.title +
    " " +
    content.parents[0].name +
    " " +
    content.parents[2].name +
    " " +
    content.parents[1].name

  return (
    <OLayoutMatch
      tree={tree}
      website={website}
      website_lang={website_lang}
      breadcrumbs={breadcrumbs}
      subnavigation={subnavigation}
      liveMatchData={matchData}
      // subnavigationDropdown2={Object.values(seasonNavigation)}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={full_slug}
        title={title}
      />
      <main
        className={
          "ef-content ef-content--match-live-ticker " +
          (matchData.liveticker_active ? " --is-live" : "") +
          (matchData.period === period.FULL_TIME ? " --is-fulltime" : "")
        }
      >
        <div className="ef-match-meta" data-theme="light">
          <OMatchHeader matchData={matchData} expansionIsOpen={false} />
        </div>
        <header className="ef-content__header">
          <div className="ef-container ef-container--l">
            <h1 className="ef-heading ef-heading--2">{content.headline}</h1>
          </div>
        </header>
        <OLiveTickerTeaserBlock matchData={matchData} />
      </main>
      <MShare title={matchNode.name} website={website} disabled="0" />
    </OLayoutMatch>
  )
}

export default MatchLiveTickerPage
