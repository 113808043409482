import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import OLayoutMatch from "gatsby-component-lib/components-legacy/organisms/o-layout_match"
import OMatchHeader from "gatsby-component-lib/components-legacy/organisms/o-match-header"
import OStadiumAdBlock from "gatsby-component-lib/components-legacy/organisms/o-stadium-ad-block"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import { LiveMatchContext } from "gatsby-component-lib/context/LiveMatchContext"
import React, { useContext } from "react"
import Helmet from "react-helmet"
import { period } from "gatsby-component-lib"

export const query = graphql`
  query MatchStatsQuery($match_uid: String, $uid: String) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    match: allTypo3Page(
      filter: { uid: { eq: $match_uid }, type: { in: "match" } }
    ) {
      edges {
        node {
          id
          page_uid
          name
          type
          website
          full_slug
          title
          data {
            liveticker_active
            attendance
            city
            definite
            id
            matchday
            referees {
              last_name
              first_name
              role
              birthday
            }
            team_away {
              id
              team_name
              team_initials_name
              formation_used
              images {
                liveticker
                liveticker_retina
                medium_square
                micro_square
                mini_square
                original
              }
            }
            team_home {
              id
              team_name
              team_initials_name
              formation_used
              images {
                liveticker
                liveticker_retina
                medium_square
                micro_square
                mini_square
                original
              }
            }
            team_away_half_score
            team_away_ninety_score
            team_away_penalty_score
            team_away_extra_score
            team_away_final_score
            team_home_half_score
            team_home_ninety_score
            team_home_penalty_score
            team_home_extra_score
            team_home_final_score
            venue
            match_uid
            competition {
              id
              slug
              title
            }
            season {
              id
              slug
              title
            }
            attendance
            kickoff
            kickoff_timezone
            period
            opta_events {
              id
              assist_person_id
              assist_person_name
              event_type
              person_id
              period
              person_name
              person_name_off
              person_name_on
              person_off_id
              person_on_id
              team
              team_name
              time
            }
            ticker_events {
              phase
              event_type
              time
              time_additional
              description
              html_description
              image_src
            }
          }
        }
      }
    }
  }
`

const MatchStatisticsPage = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    website_lang,
    website_theme,
    footerPageNodeContent,
    subnavigation,
  } = pageContext
  const content = JSON.parse(data.page.content)

  const { t } = useTranslation()

  const metadata = content.metadata

  const matchNode = data.match.edges[0] ? data.match.edges[0].node : {}
  let matchData = matchNode ? matchNode.data : {}

  const matchContext = useContext(LiveMatchContext)
  if (
    matchContext &&
    matchContext.liveMatchData &&
    matchContext.liveMatchData.liveticker_active &&
    parseInt(matchData.id) === parseInt(matchContext.matchId)
  ) {
    matchData = matchContext.liveMatchData
  }

  const second_breadcrumb = ["profis", "en"].includes(website)
    ? {
        name: t("Season"),
        full_slug: "/" + t("SeasonSlug"),
      }
    : content.parents.reverse()[1]

  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    second_breadcrumb,
  ]

  let title =
    content.title +
    " " +
    content.parents[0].name +
    " " +
    content.parents[2].name +
    " " +
    content.parents[1].name

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://secure.widget.cloud.opta.net/v3/css/v3.football.opta-widgets.css"
        />
        <script
          async={true}
          src={"https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"}
        />
      </Helmet>
      <OLayoutMatch
        tree={tree}
        title={metadata ? metadata.title : ""}
        website={website}
        website_lang={website_lang}
        subnavigation={subnavigation}
        liveMatchData={matchData}
        breadcrumbs={breadcrumbs}
        website_theme={website_theme}
        footerPageNodeContent={footerPageNodeContent}
        showAds={!content.hide_ads}
      >
        <TSeo
          website_theme={website_theme}
          website={website}
          content={content}
          full_slug={full_slug}
          title={title}
        />
        <main
          className={
            "ef-content " +
            (matchData.period === period.FULL_TIME ? " --is-fulltime" : "") +
            ((matchContext
            ? matchContext.matchIsLive
            : matchData.liveticker_active)
              ? " --is-live"
              : "")
          }
        >
          <div className="ef-match-meta" data-theme="light">
            <OMatchHeader matchData={matchData} website={website} />
          </div>
          <header className="ef-content__header">
            <div className="ef-container ef-container--l">
              <h1 className="ef-heading ef-heading--2">{content.headline}</h1>
            </div>
          </header>
          <div className="ef-content__block ef-content__block--match-stats">
            <div className="ef-match-stats">
              <div className="ef-container ef-container--l">
                <div className="opta-widget-container">
                  {matchData && matchData.competition && (
                    <opta-widget
                      widget="matchstats_pro"
                      competition={matchData.competition.id}
                      season={matchData.season.id}
                      match={matchData.id}
                      template="team_graphs"
                      live="false"
                      navigation="tabs_more"
                      default_nav="1"
                      show_match_header="true"
                      show_score="true"
                      show_halftime_score="true"
                      show_crests="false"
                      show_competition_name="true"
                      graph_style="full"
                      show_date="false"
                      date_format="dddd D MMMM YYYY"
                      show_timecontrols="true"
                      show_timecontrols_buttons="true"
                      show_timecontrols_bar="true"
                      extended_content="false"
                      rounding="1"
                      competition_naming="full"
                      team_naming="full"
                      show_live="false"
                      show_logo="true"
                      show_title="true"
                      breakpoints="400, 700"
                      sport="football"
                    ></opta-widget>
                  )}
                </div>
              </div>
            </div>
          </div>

          <OStadiumAdBlock type="stadium_ad_stats_block" />
        </main>
        <MShare
          title={metadata ? metadata.title : ""}
          website={website}
          disabled={
            content && content.disable_share_button
              ? content.disable_share_button
              : 0
          }
        />
      </OLayoutMatch>
    </>
  )
}

export default MatchStatisticsPage
