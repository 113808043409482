import React from "react"

import Slider from "react-slick"
import AImage from "../atoms/a-image"

// import ODebug from "gatsby-component-lib/components-legacy/organisms/o-debug"

const OGalleryDotSliderBlock = props => {
  let images =
    props.data && props.data.image
      ? props.data.image instanceof Array
        ? props.data.image
        : [props.data.image]
      : []
  // MK: Die Bilder kommen in einer Galerie als imageS an
  if (images.length === 0) {
    images = props.data.images ? props.data.images : []
  }

  function EfNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--next " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Prev
      </span>
    )
  }

  function EfPrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--prev " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Next
      </span>
    )
  }

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <EfPrevArrow />,
    nextArrow: <EfNextArrow />,
    mobileFirst: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 4000,
    variableWidth: false,
    dots: true,
    dotsClass: "ef-slider__dots",
    appendDots: dots => <ul>{dots}</ul>,
  }

  return (
    <section className="ef-content__block ef-content__block--gallery-slider">
      <Slider
        {...settings}
        className="ef-slider ef-gallery-slider ef-gallery-slider--dots"
      >
        {images.map((item, index) => (
          <div className="ef-slide" key={index}>
            <div className="ef-gallery-card">
              <div className="ef-media ef-media--3x2">
                <AImage
                  add_class="ef-media__image ef-media__image--auto ef-gallery-card__image"
                  image_size="l"
                  image={item}
                  aspectRatio="3:2"
                />
              </div>
              <div className="ef-caption">
                {item.description ? item.description : item.title}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default OGalleryDotSliderBlock
