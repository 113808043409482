import { useLocation } from "@reach/router"
import "moment/locale/de"
import queryString from "query-string"
import React, { useEffect, useState } from "react"
import { concatStrings } from "../../utilities/utilities"
import OFooter from "../organisms/o-footer"
import ONavigation from "../organisms/o-navigation"
import OStickyAdBlock from "../organisms/o-sticky-ad-block"
import TAlgoliaSearch from "../technical/t-algolia-search"
import TLayoutScriptHelmet from "../technical/t-layout_script-helmet"
import TScriptTags from "../technical/t-script-tags"
import { LayoutContext, loadSvg } from "./o-layout"

const OLayoutMatch = ({
  children,
  isSearchPage,
  searchOptions,
  isNewsListPage,
  tree,
  website,
  website_theme,
  website_lang,
  breadcrumbs,
  subnavigation,
  subnavigationDropdown1,
  subnavigationDropdown2,
  footerPageNodeContent,
  liveMatchData,
  showAds,
}) => {
  const location = useLocation()
  const initialQuery = queryString.parse(location.search).q || ""
  const [query, setQuery] = useState(initialQuery)

  // componentDidMount
  useEffect(() => {
    loadSvg()
  }, [])

  const getSubmenuClass = () => {
    return isSearchPage || (subnavigation && subnavigation.length)
      ? "--has-submenu"
      : null
  }

  // Submnu logic
  const filterTypesForCurrentMatchState = (
    definite,
    period,
    liveticker_active
  ) => {
    if (
      (definite === true || definite === false) &&
      (period === "PreMatch" || period === "Postponed")
    ) {
      return [
        "match_matches_page",
        "match_prereport_page",
        "match_league_table_page",
      ]
    } else if (
      period === "FullTime" &&
      (liveticker_active === null || liveticker_active === false)
    ) {
      return [
        "match_voices_page",
        "match_report_page",
        "match_liveticker_page",
        "match_matches_page",
        "match_league_table_page",
        "match_lineup_page",
        "match_statistics_page",
        "match_media_page",
        "match_prereport_page",
      ]
    } else if (liveticker_active === true) {
      return [
        "match_matches_page",
        "match_prereport_page",
        "match_league_table_page",
        "match_liveticker_page",
        "match_lineup_page",
        "match_statistics_page",
        "match_media_page",
      ]
    }
    return []
  }
  const checkContentByPageType = item => {
    const whitelist = [
      "match_voices_page",
      "match_report_page",
      "match_media_page",
    ]
    return whitelist.includes(item.type)
      ? item.data.body_is_empty === null
      : true
  }
  const clearSubnavigation = subnavigation => {
    const types = filterTypesForCurrentMatchState(
      liveMatchData.definite,
      liveMatchData.period,
      liveMatchData.liveticker_active,
      liveMatchData.kickoff
    )
    return subnavigation
      ? subnavigation
          .filter(item => types.includes(item.type))
          .filter(item => checkContentByPageType(item))
          .sort((node1, node2) => node1.sorting - node2.sorting)
      : []
  }
  const matchSubnavigation = clearSubnavigation(subnavigation)

  return (
    <>
      <TScriptTags>
        <TLayoutScriptHelmet />
        <LayoutContext.Provider
          value={{
            isSearchPage: isSearchPage,
            website: website,
            showAds: showAds,
          }}
        >
          <TAlgoliaSearch
            website={website}
            lang={website_lang}
            initialQuery={initialQuery}
            searchOptions={searchOptions}
            propagateQuery={setQuery}
          >
            <div className={concatStrings("ef-page", getSubmenuClass())}>
              <ONavigation
                tree={tree}
                website={website}
                lang={website_lang}
                isSearchPage={isSearchPage}
                isNewsListPage={isNewsListPage}
                breadcrumbs={breadcrumbs}
                matchSubnavigation={matchSubnavigation}
                liveMatchData={liveMatchData}
                subnavigationDropdown1={subnavigationDropdown1}
                subnavigationDropdown2={subnavigationDropdown2}
              />
              {children}
              <OStickyAdBlock />
              <OFooter
                website_theme={website_theme}
                content={footerPageNodeContent}
              />
            </div>
          </TAlgoliaSearch>
        </LayoutContext.Provider>
      </TScriptTags>
    </>
  )
}

OLayoutMatch.defaultProps = {
  matchlive: false,
}

export default OLayoutMatch
