import React from "react"
import { useTranslation } from "react-i18next"
import AHeading from "gatsby-component-lib/components-legacy/atoms/a-heading"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import Blocks from "gatsby-component-lib/components-legacy/_blocks"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import OArticleSliderBlock from "gatsby-component-lib/components-legacy/organisms/o-article-slider-block"
import OImageBlock from "gatsby-component-lib/components-legacy/organisms/o-image-block"
import ORichtextBlock from "gatsby-component-lib/components-legacy/organisms/o-richtext-block"
import { graphql } from "gatsby"
import OArticleSliderBlockHOC from "gatsby-component-lib/components-legacy/organisms/o-article-slider-block-hoc"
import Moment from "react-moment"
import { DATE_TIME_FORMATS } from "gatsby-component-lib/utilities/date-time-formats"
import OTagsBlock from "gatsby-component-lib/components-legacy/organisms/o-tags-block"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import OGalleryDotSliderBlock from "gatsby-component-lib/components-legacy/organisms/o-gallery-dot-slider-block"

export const query = graphql`
  query RelatedNewsQuery($categories: [String], $uid: String) {
    news: typo3Page(uid: { eq: $uid }) {
      content
    }
    relatedNews: allTypo3Page(
      sort: { order: DESC, fields: display_datetime }
      filter: {
        data: { categories: { elemMatch: { title: { in: $categories } } } }
        type: { in: "news_page" }
      }
      limit: 7
    ) {
      edges {
        node {
          uid
          id
          slug
          full_slug
          display_datetime
          content
          type
          data {
            categories {
              uid
              title
              slug
            }
          }
        }
      }
    }
  }
`

const NewsPage = ({ data, pageContext }) => {
  const {
    node,
    uid,
    full_slug,
    tree,
    subnavigation,
    website,
    website_lang,
    website_theme,
    footerPageNodeContent,
  } = pageContext
  let { breadcrumbs } = pageContext

  const { t } = useTranslation()

  const relatedNews = data.relatedNews
    ? data.relatedNews.edges.filter(n => n.node.uid !== uid).slice(0, 5) // die ersten 6 News ohne die aktuelle News
    : []

  const content = JSON.parse(data.news.content)

  breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    {
      name: t("LatestNews"),
      full_slug: "/" + t("LatestNewsSlug"),
    },
  ]
  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      subnavigation={subnavigation}
      breadcrumbs={breadcrumbs}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website={website}
        website_theme={website_theme}
        content={content}
        full_slug={full_slug}
      />
      <main className="ef-content">
        <article
          className={
            node.type === "magazine"
              ? "ef-article ef-article--magazine"
              : "ef-article"
          }
        >
          <header className="ef-article__header">
            <div className="ef-container ef-container--l">
              <div className="ef-article__header-title">
                <div className="ef-kicker">
                  <div className="ef-kicker__item">
                    <Moment format={DATE_TIME_FORMATS.default}>
                      {node.display_datetime}
                    </Moment>
                  </div>
                  {content.categories && (
                    <div className="ef-kicker__item">
                      {content.categories[0].title}
                    </div>
                  )}
                </div>
                <AHeading
                  headline_level="h1"
                  headline={content.headline}
                  add_class="ef-heading--has-kicker"
                />
                {content.subline && (
                  <AHeading
                    headline_level="h3"
                    headline={content.subline}
                    add_class="ef-heading--sub"
                  />
                )}
              </div>
              <div className="ef-article__header-media">
                {content.is_evnews === true && (
                  <>
                    {content.images && content.images.length === 1 && (
                      <OImageBlock
                        data={{
                          image: content.images[0],
                          image_size: "m",
                        }}
                      />
                    )}
                    {content.images && content.images.length > 1 && (
                      <OGalleryDotSliderBlock
                        data={{
                          image: content.images,
                        }}
                      />
                    )}
                  </>
                )}

                {content.is_evnews !== true && (
                  <>
                    {content.images && content.images.length === 1 && (
                      <OImageBlock
                        data={{
                          image: content.images[0],
                          image_size: "l",
                        }}
                      />
                    )}
                    {content.images && content.images.length > 1 && (
                      <OGalleryDotSliderBlock
                        data={{
                          image: content.images,
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </header>

          <section className="ef-article__body">
            {content.text && content.text.length > 0 && (
              <ORichtextBlock
                data={{
                  text: content.text,
                }}
              />
            )}

            {content.body &&
              content.body.map &&
              content.body.map(block =>
                Blocks[block.component]
                  ? React.createElement(Blocks[block.component], {
                      key: block.uid,
                      data: block,
                      pageData: {},
                    })
                  : `Block ${block.component} in ${block.uid} not created yet`
              )}
          </section>

          <footer className="ef-article__footer">
            {content.tags && (
              <OTagsBlock
                data={{
                  tags: content.tags,
                }}
              />
            )}
            {relatedNews && (
              <OArticleSliderBlockHOC
                news={relatedNews}
                card_content_type="article_card"
              />
            )}
            {content.articles && <OArticleSliderBlock />}
          </footer>
        </article>
      </main>
      <MShare
        title={content.title}
        website={website}
        disabled={
          content && content.disable_share_button
            ? content.disable_share_button
            : 0
        }
      />
    </OLayout>
  )
}

export default NewsPage
