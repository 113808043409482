import React from "react"

import BlankoPage from "../templates/page-types/blanko_page-template"
import CompetitionPage from "../templates/page-types/competition_page-template"
import ContentPage from "../templates/page-types/content_page-template"
import CurrentMatchesPage from "../templates/page-types/current_matches_page-template"
import DefaultPage from "../templates/page-types/default_page-template"
import EventPage from "../templates/page-types/event_page-template"
import EventsOverviewPage from "../templates/page-types/events_overview_page-template"
import GalleryPage from "../templates/page-types/gallery_page-template"
import HeroPage from "../templates/page-types/hero_page-template"
import HighlightPage from "../templates/page-types/highlight_page-template"
import LeagueTablePage from "../templates/page-types/league_table_page-template"
import ManualPersonPage from "../templates/page-types/manual_person_page-template"
import MatchLeagueTablePage from "../templates/page-types/match_league_table_page-template"
import MatchLineupPage from "../templates/page-types/match_lineup_page-template"
import MatchLivetickerPage from "../templates/page-types/match_liveticker_page-template"
import MatchMatchesPage from "../templates/page-types/match_matches_page-template"
import MatchMediaPage from "../templates/page-types/match_media_page-template"
import MatchPrereportPage from "../templates/page-types/match_prereport_page-template"
import MatchReportPage from "../templates/page-types/match_report_page-template"
import MatchStatisticsPage from "../templates/page-types/match_statistics_page-template"
import MatchVoicesPage from "../templates/page-types/match_voices_page-template"
import MediaPage from "../templates/page-types/media_page-template"
import NewsPage from "../templates/page-types/news_page-template"
import PersonPage from "../templates/page-types/person_page-template"
import SeasonPage from "../templates/page-types/season_page-template"
import StartPage from "../templates/page-types/start_page-template"
import TeamPage from "../templates/page-types/team_page-template"
import TeamStaffPage from "../templates/page-types/team_staff_page-template"
import TeamStatisticsPage from "../templates/page-types/team_statistics_page-template"
import VideoOverviewPage from "../templates/page-types/video_overview_page-template"
import VideoPage from "../templates/page-types/video_page-template"

const pageTypeMapping = {
  blanko_page: BlankoPage,
  competition_page: CompetitionPage,
  content_page: ContentPage,
  current_matches_page: CurrentMatchesPage,
  default_page: DefaultPage,
  event_page: EventPage,
  events_overview_page: EventsOverviewPage,
  gallery_page: GalleryPage,
  hero_page: HeroPage,
  highlight_page: HighlightPage,
  league_table_page: LeagueTablePage,
  manual_person_page: ManualPersonPage,
  match_league_table_page: MatchLeagueTablePage,
  match_lineup_page: MatchLineupPage,
  match_liveticker_page: MatchLivetickerPage,
  match_matches_page: MatchMatchesPage,
  match_media_page: MatchMediaPage,
  match_prereport_page: MatchPrereportPage,
  match_report_page: MatchReportPage,
  match_statistics_page: MatchStatisticsPage,
  match_voices_page: MatchVoicesPage,
  media_page: MediaPage,
  news_page: NewsPage,
  person_page: PersonPage,
  season_page: SeasonPage,
  start_page: StartPage,
  team_page: TeamPage,
  team_staff_page: TeamStaffPage,
  team_statistics_page: TeamStatisticsPage,
  video_overview_page: VideoOverviewPage,
  video_page: VideoPage,
}

class Typo3Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = { story: null, info: "Warte auf Preview-Inhalte von Typo3" }
  }

  componentDidMount() {
    this.initEvents()
  }

  initEvents() {
    const self = this
    async function displayMessage(evt) {
      const story = JSON.parse(evt.data)

      self.setState({
        info: "Rendering startet",
      })
      self.setState({
        story: story,
      })
    }

    if (window.addEventListener) {
      // For standards-compliant web browsers
      window.addEventListener("message", displayMessage, false)
    } else {
      window.attachEvent("onmessage", displayMessage)
    }
  }

  render() {
    if (this.state.story == null) {
      return (
        <div style={{ margin: "100px", textAlign: "center" }}>
          {this.state.info}
        </div>
      )
    } else {
      this.state.story.content = JSON.stringify(this.state.story.content)

      let pageType = this.state.story.type
      let pageContext = {
        website: this.state.story.website,
        node: this.state.story,
      }
      return !!pageTypeMapping[pageType]
        ? React.createElement(pageTypeMapping[pageType], {
            data: {},
            pageContext: pageContext,
          })
        : `Page ${pageType} not previewable`
    }
  }
}

export default Typo3Page
