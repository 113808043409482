import React from "react"

import tvBackground from "../../images/tv-cta.jpg"
import ALink from "../atoms/a-link"

const OTVBanner = props => {
  if (props.authenticated) {
    return <></>
  }

  return (
    <section className="ef-content__block ef-content__block--tv-cta">
      <div
        className="ef-tv-cta"
        style={{
          backgroundImage: "url(" + tvBackground + ")",
        }}
      >
        <div className="ef-container ef-container--l">
          <div className="ef-tv-cta__text">
            <h2 className="ef-heading ef-heading--2 ef-tv-cta__heading">
              EintrachtTV ab 18,99 € im Jahr
            </h2>
            <p className="ef-paragraph ef-paragraph--lead">
              Alle Videos und Live-Spiele unbegrenzt genießen
            </p>
          </div>
          <div className="ef-tv-cta__action">
            <ALink
              className="ef-button ef-button--primary ef-button--large"
              link="#"
            >
              Jetzt abonnieren
            </ALink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OTVBanner
