import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import OLeagueTableBlock from "gatsby-component-lib/components-legacy/organisms/o-league-table-block"
import OMatchHeader from "gatsby-component-lib/components-legacy/organisms/o-match-header"
import OStadiumAdBlock from "gatsby-component-lib/components-legacy/organisms/o-stadium-ad-block"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import { LiveMatchContext } from "gatsby-component-lib/context/LiveMatchContext"
import React, { useContext } from "react"

export const query = graphql`
  query LeagueTableQuery(
    $league_table_uid: String
    $match_uid: String
    $uid: String
  ) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    match: allTypo3Page(
      filter: { uid: { eq: $match_uid }, type: { in: "match" } }
    ) {
      nodes {
        id
        page_uid
        name
        type
        website
        full_slug
        title
        data {
          attendance
          city
          definite
          id
          matchday
          referees {
            last_name
            first_name
            role
            birthday
          }
          team_away {
            id
            team_name
            team_initials_name
            formation_used
            images {
              liveticker
              liveticker_retina
              medium_square
              micro_square
              mini_square
              original
            }
          }
          team_home {
            id
            team_name
            team_initials_name
            formation_used
            images {
              liveticker
              liveticker_retina
              medium_square
              micro_square
              mini_square
              original
            }
          }
          team_away_half_score
          team_away_ninety_score
          team_away_penalty_score
          team_away_extra_score
          team_away_final_score
          team_home_half_score
          team_home_ninety_score
          team_home_penalty_score
          team_home_extra_score
          team_home_final_score
          venue
          match_uid
          competition {
            id
            slug
            title
          }
          season {
            id
            slug
            title
          }
          attendance
          kickoff
          kickoff_timezone
          period
        }
      }
    }
    leagueTable: allTypo3Page(
      filter: { uid: { eq: $league_table_uid }, type: { in: "standing" } }
    ) {
      nodes {
        uid
        id
        page_uid
        name
        type
        website
        full_slug
        standing_type
        data {
          period
          matchday
          match_uid
          competition {
            id
            slug
            title
          }
          season {
            id
            slug
            title
          }
          component
          matchdays {
            has_standings
            matchday
            uid
            full_slug
          }
          standings {
            full {
              drawn
              goal_balance
              goals
              goals_against
              goals_for
              info
              lost
              played
              points
              position
              position_trend
              team_id
              team_name
              team_initials_name
              team_logo_url
              won
              trends {
                home_team
                opponent_name
                team_away_final_score
                team_home_final_score
                trend
              }
            }
            home {
              drawn
              goal_balance
              goals
              goals_against
              goals_for
              info
              lost
              played
              points
              position
              position_trend
              team_id
              team_name
              team_initials_name
              team_logo_url
              won
              trends {
                home_team
                opponent_name
                team_away_final_score
                team_home_final_score
                trend
              }
            }
            away {
              drawn
              goal_balance
              goals
              goals_against
              goals_for
              info
              lost
              played
              points
              position
              position_trend
              team_id
              team_name
              team_logo_url
              won
              trends {
                home_team
                opponent_name
                team_away_final_score
                team_home_final_score
                trend
              }
            }
          }
        }
      }
    }
  }
`

const LeagueTableTemplate = ({ data, pageContext }) => {
  const {
    node,
    tree,
    website,
    website_lang,
    website_theme,
    footerPageNodeContent,
    subnavigation,
  } = pageContext
  const { t } = useTranslation()
  const content = JSON.parse(data.page.content)

  const leagueTableNode =
    data.leagueTable.nodes.length > 0 ? data.leagueTable.nodes[0] : []
  const leagueTableData = leagueTableNode ? leagueTableNode.data : null

  const nodeContent = JSON.parse(data.page.content)

  const matchNode = data.match.nodes ? data.match.nodes[0] : {}
  let matchData = matchNode ? matchNode.data : {}

  const matchContext = useContext(LiveMatchContext)
  if (
    matchContext &&
    matchContext.liveMatchData &&
    matchContext.liveMatchData.liveticker_active &&
    parseInt(matchData.id) === parseInt(matchContext.matchId)
  ) {
    matchData = matchContext.liveMatchData
  }

  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    {
      name: t("Season"),
      full_slug: "/" + t("SeasonSlug"),
    },
  ]

  let title =
    content.title +
    " " +
    (leagueTableNode && leagueTableNode.data && leagueTableNode.data.matchday) +
    ". " +
    t("Stats.Matchday") +
    " " +
    content.parents[1].name +
    " " +
    content.parents[0].name

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      subnavigation={subnavigation}
      breadcrumbs={breadcrumbs}
      // subnavigationDropdown2={Object.values(seasonNavigation)}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
      showAds={!nodeContent.hide_ads}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={node.full_slug}
        title={title}
      />
      <main className="ef-content ef-content--league-table">
        <OMatchHeader matchData={matchData} website={website} />
        {leagueTableNode && leagueTableData && (
          <OLeagueTableBlock
            headline={content.headline}
            content={leagueTableNode}
            tableData={leagueTableData}
            matchDayTableLinks={leagueTableData.matchdays || []}
          />
        )}
        <OStadiumAdBlock type="stadium_ad_league_table_block" />
      </main>
      {leagueTableNode && (
        <MShare
          title={leagueTableNode.name}
          website={website}
          disabled={
            leagueTableNode && leagueTableNode.disable_share_button
              ? leagueTableNode.disable_share_button
              : 0
          }
        />
      )}
    </OLayout>
  )
}

export default LeagueTableTemplate
