import { graphql, Link } from "gatsby"
import { OVideoGridBlock, OVideoSliderBlock } from "gatsby-component-lib"
import MVideoDetailHeader from "gatsby-component-lib/components-legacy/molecules/m-video-detail-header"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import OTVBanner from "gatsby-component-lib/components-legacy/organisms/o-tv-banner"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import React, { useState } from "react"
import moment from "moment"

import { useCookies } from "react-cookie"

export const OVideoBlockHOC = ({ videos, blockType }) => {
  const mappedVideos = []
  Object.values(videos).map(video => {
    const content = JSON.parse(video.node.content)

    let categories = ""
    if (content.categories && content.categories[0]) {
      categories = content.categories[0].title
    }

    const kickerText = `${moment(video.node.display_datetime).format(
      "DD.MM.YYYY"
    )} / ${categories}`

    mappedVideos.push({
      videoUID: content.video_uid,
      link: video.node.full_slug,
      categories: categories,
      kicker: kickerText,
      teaser: content.teaser.replace(/\\"/g, '"'),
      payment: content.payment,
      headline: content.title,
      subline: content.subline,
      image: content.images ? content.images[0] : "",
      hover_image:
        content.images && content.images.length > 1 ? content.images[1] : "",
    })
  })

  if (blockType === "grid") {
    return (
      <OVideoGridBlock
        data={{ card_items: mappedVideos }}
        card_content_type="video_card"
      />
    )
  } else {
    return (
      <OVideoSliderBlock
        data={{ card_items: mappedVideos }}
        card_content_type="video_card"
      />
    )
  }
}

export const query = graphql`
  query SeasonsVideoPageQuery($categories: [String], $uid: String) {
    page: typo3Page(uid: { eq: $uid }) {
      content
      display_datetime
    }
    relatedVideos: allTypo3Page(
      sort: { order: DESC, fields: display_datetime }
      filter: {
        data: { categories: { elemMatch: { title: { in: $categories } } } }
        type: { in: "video_page" }
      }
      limit: 7
    ) {
      edges {
        node {
          uid
          id
          slug
          full_slug
          content
          type
          data {
            categories {
              uid
              title
              slug
            }
          }
        }
      }
    }
  }
`

const VideoPage = ({ data, pageContext }) => {
  const {
    node,
    uid,
    full_slug,
    tree,
    subnavigation,
    website,
    website_lang,
    website_theme,
    footerPageNodeContent,
  } = pageContext

  let { breadcrumbs } = pageContext

  const content = JSON.parse(data.page.content)

  const currentRootCategory = node.data.root_categories
    ? node.data.root_categories[0]
    : []

  const relatedVideos = data.relatedVideos
    ? data.relatedVideos.edges.filter(n => n.node.uid !== uid).slice(0, 5) // die ersten 6 Videos ohne die aktuelle Videos
    : []

  const [cookies] = useCookies(["yum_jwt"])
  const [authenticated] = useState(cookies && cookies.yum_jwt)

  return (
    <OLayout
      tree={tree}
      website={website}
      subnavigation={subnavigation}
      breadcrumbs={breadcrumbs}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website={website}
        content={content}
        full_slug={full_slug}
        og_title={content.metadata.ogtitle}
        og_image={content.metadata.ogimage.src}
        og_image_width="780"
        og_image_height="450"
        og_locale="de_DE"
        twitter_card="summary"
        twitter_title={content.metadata.title}
        website_theme={website_theme}
      />
      <main className="ef-content ef-content--tv-video-detail">
        <MVideoDetailHeader
          display_datetime={data.page.display_datetime}
          content={content}
          website={website}
          currentRootCategory={currentRootCategory}
        />

        {relatedVideos && (
          <OVideoBlockHOC blockType="slider" videos={relatedVideos} />
        )}
        {/* KOMPONENTE FÜR SLIDER MIT OPTIONALER ÜBERSCHRIFT ENDE */}

        <OTVBanner authenticated={authenticated} />
      </main>
    </OLayout>
  )
}

export default VideoPage
