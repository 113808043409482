/* eslint-disable no-unused-vars */
import { graphql } from "gatsby"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import { squadSubNavigation, clean_fullslug } from "gatsby-component-lib"
import AChartDonut from "gatsby-component-lib/components-legacy/atoms/a-chart-donut"
import { circleDasharray } from "gatsby-component-lib"
import OArticleSliderBlockHOC from "gatsby-component-lib/components-legacy/organisms/o-article-slider-block-hoc"
import OPlayerFullscreenHeroBlock from "gatsby-component-lib/components-legacy/organisms/o-player-fullscreen-hero-block"
import OPlayerSliderBlock from "gatsby-component-lib/components-legacy/organisms/o-player-slider-block"
import TContentBody from "gatsby-component-lib/components-legacy/technical/t-content-body"

// import ODebug from "../../components/organisms/o-debug"

export const query = graphql`
  query PersonPageQuery(
    $person_uid: String
    $team_uid: String
    $website: String
    $uid: String
  ) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    person: allTypo3Page(filter: { uid: { eq: $person_uid } }) {
      edges {
        node {
          uid
          slug
          data {
            person {
              id
              birthday
              birthplace
              weight
              height
              jersey_number
              country
              slug
              position
              is_player
              stats {
                games_played
                starts
                time_played
                duels_lost
                duels_won
                goals
                goal_assists
                penalty_goals
                right_foot_goals
                left_foot_goals
                goals_from_inside_box
                goals_from_outside_box
                headed_goals
                total_passes
                successful_long_passes
                successful_short_passes
                touches
                substitute_on
                substitute_off
                yellow_cards
                yellow_red_cards
                red_cards
              }
            }
          }
        }
      }
    }
    peopleData: allTypo3Page(filter: { uid: { eq: $team_uid } }) {
      edges {
        node {
          uid
          slug
          full_slug
          type
          name
          data {
            season {
              id
              title
              slug
            }
            players {
              person {
                id
                uid
                title
                slug
                full_slug
                position
                jersey_number
              }
            }
            staff {
              person {
                id
                uid
                title
                slug
                full_slug
                position
              }
            }
          }
        }
      }
    }
    peopleTypo3Data: allTypo3Page(
      filter: { type: { in: ["person_page"] }, website: { in: [$website] } }
    ) {
      edges {
        node {
          data {
            person_uid
            transparent_image
          }
        }
      }
    }
    peopleNews: allTypo3Page(
      filter: {
        data: { person_ids: { in: [$person_uid] } }
        type: { in: "news_page" }
      }
    ) {
      edges {
        node {
          data {
            match_uid
            person_ids
          }
          id
          name
          display_datetime
          type
          website
          content
          full_slug
        }
      }
    }
  }
`
const PersonPageTemplate = ({ data, pageContext }) => {
  const {
    node,
    full_slug,
    website,
    website_lang,
    website_theme,
    tree,
    subnavigation,
    footerPageNodeContent,
  } = pageContext

  const { t } = useTranslation()
  const content = JSON.parse(data.page.content)
  const [isActive, setIsActive] = useState(false)

  // Aus allen Daten ein Person-Objekt machen
  let person = Object.assign(node.data, {
    title: node && node.name ? node.name : "",
    slug: node && node.slug ? clean_fullslug(node.slug) : "",
    hero_image: node && node.data ? node.data.hero_image : "",
    birthday:
      data &&
      data.person &&
      data.person.edges &&
      data.person.edges[0] &&
      data.person.edges[0].node &&
      data.person.edges[0].node.data
        ? data.person.edges[0].node.data.person.birthday
        : "",
    birthplace:
      data &&
      data.person &&
      data.person.edges &&
      data.person.edges[0] &&
      data.person.edges[0].node &&
      data.person.edges[0].node.data
        ? data.person.edges[0].node.data.person.birthplace
        : "",
    weight:
      data &&
      data.person &&
      data.person.edges &&
      data.person.edges[0] &&
      data.person.edges[0].node &&
      data.person.edges[0].node.data
        ? data.person.edges[0].node.data.person.weight
        : "",
    height:
      data &&
      data.person &&
      data.person.edges &&
      data.person.edges[0] &&
      data.person.edges[0].node &&
      data.person.edges[0].node.data
        ? data.person.edges[0].node.data.person.height
        : "",
    jersey_number:
      data &&
      data.person &&
      data.person.edges &&
      data.person.edges[0] &&
      data.person.edges[0].node &&
      data.person.edges[0].node.data
        ? data.person.edges[0].node.data.person.jersey_number
        : "",
    country:
      data &&
      data.person &&
      data.person.edges &&
      data.person.edges[0] &&
      data.person.edges[0].node &&
      data.person.edges[0].node.data
        ? data.person.edges[0].node.data.person.country
        : "",
    stats:
      data &&
      data.person &&
      data.person.edges &&
      data.person.edges[0] &&
      data.person.edges[0].node &&
      data.person.edges[0].node.data
        ? data.person.edges[0].node.data.person.stats
        : {},
    twitter_url: node && node.data ? node.data.twitter_url : "",
    instagram_url: node && node.data ? node.data.instagram_url : "",
    facebook_url: node && node.data ? node.data.facebook_url : "",
    website_url: node && node.data ? node.data.website_url : "",
    ef_player_since: node && node.data ? node.data.ef_player_since : "",
    body: content && content.body,
    position:
      data &&
      data.person &&
      data.person.edges &&
      data.person.edges[0] &&
      data.person.edges[0].node &&
      data.person.edges[0].node.data
        ? data.person.edges[0].node.data.person.position
        : "",
    is_player:
      data &&
      data.person &&
      data.person.edges &&
      data.person.edges[0] &&
      data.person.edges[0].node &&
      data.person.edges[0].node.data
        ? data.person.edges[0].node.data.person.is_player
        : false,
  })

  let people = []
  if (person.is_player && data.peopleData.edges && data.peopleData.edges[0]) {
    people = data.peopleData.edges[0].node.data.players.sort(function(a, b) {
      return a.person.jersey_number < b.person.jersey_number ? -1 : 1
    })
  } else {
    if (data.peopleData.edges && data.peopleData.edges[0]) {
      people = data.peopleData.edges[0].node.data.staff
    }
  }

  let peopleData = data.peopleData.edges
  for (let i in people) {
    // PersonData ist die Typo3 Seite des Spielers (wir brauchen das transparente Bild)
    let personData = false
    personData = data.peopleTypo3Data.edges.filter(
      edge =>
        edge.node.data !== null &&
        edge.node.data.person_uid === people[i]["person"]["uid"]
    )

    if (personData && personData[0] && personData[0].node) {
      people[i]["person"] = Object.assign(people[i]["person"], {
        transparent_image: personData[0].node.data.transparent_image,
      })
    } else {
      people[i]["person"] = Object.assign(people[i]["person"], {
        transparent_image: "",
      })
    }
  }

  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    { name: t("Team"), full_slug: content.parents[0].full_slug },
  ]

  // DropDown oben rechts
  let subnavigationDropdown1 = []
  subnavigationDropdown1.push({
    link: full_slug,
    title: person.is_player
      ? t("Dropdowns.AllPlayers")
      : t("Dropdowns.AllOfficials"),
    active: false,
  })
  for (let i in people) {
    let p = people[i]
    subnavigationDropdown1.push({
      link: clean_fullslug(p.person.full_slug),
      title: person.is_player
        ? p.person.jersey_number + " " + p.person.title
        : p.person.title,
      active: p.person.uid === person.person_uid ? true : false,
    })
  }

  // NEWS
  const news = data.peopleNews.edges

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      breadcrumbs={breadcrumbs}
      subnavigation={subnavigation}
      subnavigationDropdown1={subnavigationDropdown1}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={node.full_slug}
      />
      <main className="ef-content ef-content--player">
        <header className="ef-content__hero">
          <OPlayerFullscreenHeroBlock person={person} />

          {person.is_player && (
            <>
              <section className="ef-content__block ef-content__block--section-header-top">
                <div className="ef-section-header">
                  <h4 className="ef-heading ef-heading--4 ef-section-header__heading">
                    {t("Statistic")}
                  </h4>
                </div>
              </section>

              <section className="ef-content__block ef-content__block--player-stats">
                <div className="ef-container--l">
                  <div className="ef-clipped">
                    <div
                      className={
                        "ef-clipped__items" + (isActive ? " --is-active" : "")
                      }
                    >
                      <div className="ef-player-stats">
                        <div className="ef-player-stats__general">
                          <div className="ef-player-stats__unit ef-player-stats__unit--general js-viewport-check --is-in-view">
                            <AChartDonut
                              dashArray1={circleDasharray(
                                person.stats.games_played,
                                person.stats.starts
                              )}
                            />
                            <ul className="ef-player-stats__list">
                              <li className="ef-player-stats__list-item ef-player-stats__list-item--primary">
                                <div className="ef-player-stats__label">
                                  {t("Stats.GamesPlayed")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.games_played &&
                                    person.stats.games_played}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__legend ef-player-stats__legend--primary"></div>
                                <div className="ef-player-stats__label">
                                  {t("Stats.Starts")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.starts && person.stats.starts}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__legend ef-player-stats__legend--secondary"></div>
                                <div className="ef-player-stats__label">
                                  {t("Stats.SubstituteOn")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.substitute_on &&
                                    person.stats.substitute_on}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.SubstituteOff")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.substitute_off &&
                                    person.stats.substitute_off}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.TimePlayed")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.time_played &&
                                    person.stats.time_played + "'"}
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="ef-player-stats__unit ef-player-stats__unit--general js-viewport-check --is-in-view">
                            <AChartDonut
                              dashArray1={circleDasharray(
                                person.stats.goals + person.stats.goal_assists,
                                person.stats.goals
                              )}
                            />
                            <ul className="ef-player-stats__list">
                              <li className="ef-player-stats__list-item ef-player-stats__list-item--primary">
                                <div className="ef-player-stats__label">
                                  {t("Stats.Scorer")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.goals +
                                    person.stats.goal_assists}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__legend ef-player-stats__legend--primary"></div>
                                <div className="ef-player-stats__label">
                                  {t("Stats.Goals")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.goals}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__legend ef-player-stats__legend--secondary"></div>
                                <div className="ef-player-stats__label">
                                  {t("Stats.Assists")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.goal_assists}
                                </div>
                              </li>
                              {/* <li className="ef-player-stats__list-item ef-player-stats__list-item--zero">
                                <div className="ef-player-stats__label">
                                  {t("Stats.PenaltyGoals")}
                                </div>
                                <div className="ef-player-stats__value">??</div>
                              </li> */}
                            </ul>
                          </div>
                          <div className="ef-player-stats__unit ef-player-stats__unit--general js-viewport-check --is-in-view">
                            <AChartDonut
                              dashArray1={circleDasharray(
                                person.stats.duels_lost +
                                  person.stats.duels_won,
                                person.stats.duels_won
                              )}
                              subclass1="failure"
                              subclass2="success"
                            />
                            <ul className="ef-player-stats__list">
                              <li className="ef-player-stats__list-item ef-player-stats__list-item--primary">
                                <div className="ef-player-stats__label">
                                  {t("Stats.Duels")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.duels_lost +
                                    person.stats.duels_won}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__legend ef-player-stats__legend--success"></div>
                                <div className="ef-player-stats__label">
                                  {t("Stats.DuelsWon")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.duels_won}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__legend ef-player-stats__legend--failure"></div>
                                <div className="ef-player-stats__label">
                                  {t("Stats.DuelsLost")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.duels_lost}
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="ef-player-stats__unit ef-player-stats__unit--general js-viewport-check --is-in-view">
                            <AChartDonut
                              dashArray1={circleDasharray(
                                person.stats.yellow_cards +
                                  person.stats.yellow_red_cards +
                                  person.stats.red_cards,
                                person.stats.yellow_cards +
                                  person.stats.yellow_red_cards
                              )}
                              dashArray2={circleDasharray(
                                person.stats.yellow_cards +
                                  person.stats.yellow_red_cards +
                                  person.stats.red_cards,
                                person.stats.yellow_cards +
                                  person.stats.yellow_red_cards
                              )}
                              dashArray3={circleDasharray(
                                person.stats.yellow_cards +
                                  person.stats.yellow_red_cards +
                                  person.stats.red_cards,
                                person.stats.yellow_cards
                              )}
                              strokeWidth2="2"
                              subclass1="red-card"
                              subclass2="2nd-yellow-card ef-donut__circle--2nd-yellow-card-yellow"
                              subclass3="2nd-yellow-card ef-donut__circle--2nd-yellow-card-red"
                              subclass4="yellow-card"
                            />
                            <ul className="ef-player-stats__list">
                              <li className="ef-player-stats__list-item ef-player-stats__list-item--primary">
                                <div className="ef-player-stats__label">
                                  {t("Stats.Cards")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.yellow_cards +
                                    person.stats.yellow_red_cards +
                                    person.stats.red_cards}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__legend ef-player-stats__legend--yellow-card"></div>
                                <div className="ef-player-stats__label">
                                  {t("Stats.YellowCard")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.yellow_cards}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__legend ef-player-stats__legend--2nd-yellow-card"></div>
                                <div className="ef-player-stats__label">
                                  {t("Stats.YellowRedCard")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.yellow_red_cards}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__legend ef-player-stats__legend--red-card"></div>
                                <div className="ef-player-stats__label">
                                  {t("Stats.RedCard")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.red_cards}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="ef-player-stats__position">
                          <div className="ef-player-stats__unit ef-player-stats__unit--position">
                            <ul className="ef-player-stats__list">
                              <li className="ef-player-stats__list-item ef-player-stats__list-item--primary">
                                <div className="ef-player-stats__label">
                                  {t("Stats.Goals")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.goals}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.LeftFootGoals")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.left_foot_goals}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.RightFootGoals")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.right_foot_goals}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item ef-player-stats__list-item--zero">
                                <div className="ef-player-stats__label">
                                  {t("Stats.HeadedGoals")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.headed_goals}
                                </div>
                              </li>
                              {/* <li className="ef-player-stats__list-item ef-player-stats__list-item--zero">
                                <div className="ef-player-stats__label">
                                  {t("Stats.StandardGoals")}
                                </div>
                                <div className="ef-player-stats__value">??</div>
                              </li> */}
                              {/* <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.InsideBoxGoals")}
                                </div>
                                <div className="ef-player-stats__value">??</div>
                              </li> */}
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.GoalAssists")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.goal_assists}
                                </div>
                              </li>
                              {/* <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.GoalShots")}
                                </div>
                                <div className="ef-player-stats__value">??</div>
                              </li> */}
                            </ul>
                          </div>
                          <div className="ef-player-stats__unit ef-player-stats__unit--position">
                            <ul className="ef-player-stats__list">
                              <li className="ef-player-stats__list-item ef-player-stats__list-item--primary">
                                <div className="ef-player-stats__label">
                                  {t("Stats.SuccessfulPasses")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.total_passes}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.ShortPasses")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.successful_short_passes}
                                </div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.LongPasses")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.successful_long_passes}
                                </div>
                              </li>
                              {/* <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.OwnHalf")}
                                </div>
                                <div className="ef-player-stats__value">??</div>
                              </li>
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.OpponentHalf")}
                                </div>
                                <div className="ef-player-stats__value">??</div>
                              </li> */}
                              <li className="ef-player-stats__list-item">
                                <div className="ef-player-stats__label">
                                  {t("Stats.Touches")}
                                </div>
                                <div className="ef-player-stats__value">
                                  {person.stats.touches}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        "ef-clipped__trigger" +
                        (!isActive ? " --is-active" : "")
                      }
                      onClick={() => setIsActive(!isActive)}
                    >
                      <div className="ef-show-more ef-show-more--clipped">
                        <div className="ef-link-button ef-link-button--more-bottom ef-clipped__trigger-more">
                          {t("Misc.ShowMoreButton")}
                        </div>
                        <div className="ef-link-button ef-link-button--more-top ef-clipped__trigger-less">
                          {t("Misc.ShowLessButton")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </header>

        <article className="ef-article ef-article--low-level">
          <TContentBody content={content} />
          <footer className="ef-article__footer">
            <section className="ef-content__block ef-content__block--article-slider">
              {news && (
                <OArticleSliderBlockHOC
                  news={news}
                  card_content_type="article_card"
                />
              )}
            </section>
            <OPlayerSliderBlock people={people} />
          </footer>
        </article>
      </main>

      <MShare title={person.title} website={website} disabled="0" />
    </OLayout>
  )
}

export default PersonPageTemplate
