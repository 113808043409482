import React from "react"
import { useTranslation } from "react-i18next"

import { graphql } from "gatsby"

import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"

// import ODebug from "../../components/organisms/o-debug"

const EventTemplate = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    website_lang,
    website_theme,
    base_path,
    footerPageNodeContent,
  } = pageContext
  const { t } = useTranslation()
  const content = JSON.parse(data.typo3Page.content)

  let breadcrumbs = [
    {
      name: t("events"),
      full_slug: base_path,
    },
  ]

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      breadcrumbs={breadcrumbs}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={full_slug}
      />
      <main className="ef-content ef-content--dates">
        <header className="ef-content__header">
          <div className="ef-container ef-container--l">
            <div className="ef-content__header-with-filters">
              <h1 className="ef-heading ef-heading--2">{content.headline}</h1>
            </div>
          </div>
        </header>

        <section className="ef-content__block ef-content__block--data-table">
          <div className="ef-container ef-container--l">
            <div dangerouslySetInnerHTML={{ __html: content.text }} />
          </div>
        </section>
      </main>
      <MShare
        title={content.metadata ? content.metadata.title : content.title}
        website={website}
        disabled="0"
      />
    </OLayout>
  )
}

export const query = graphql`
  query($uid: String!) {
    typo3Page(uid: { eq: $uid }) {
      content
    }
  }
`

export default EventTemplate
