/* eslint-disable no-unused-vars */
import { graphql } from "gatsby"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import { squadSubNavigation, clean_fullslug } from "gatsby-component-lib"
import AChartDonut from "gatsby-component-lib/components-legacy/atoms/a-chart-donut"
import { circleDasharray } from "gatsby-component-lib"
import OArticleSliderBlockHOC from "gatsby-component-lib/components-legacy/organisms/o-article-slider-block-hoc"
import OPlayerFullscreenHeroBlock from "gatsby-component-lib/components-legacy/organisms/o-player-fullscreen-hero-block"
import OPlayerSliderBlock from "gatsby-component-lib/components-legacy/organisms/o-player-slider-block"
import TContentBody from "gatsby-component-lib/components-legacy/technical/t-content-body"
import { peopleGrouper } from "gatsby-component-lib/utilities/utilities"
import Moment from "react-moment"
import { DATE_TIME_FORMATS } from "gatsby-component-lib/utilities/date-time-formats"
import ASocialLink from "gatsby-component-lib/components-legacy/atoms/a-social-link"

import ODebug from "gatsby-component-lib/components-legacy/organisms/o-debug"
import ALink from "../../../../../gatsby-component-lib/components-legacy/atoms/a-link"

// Alle anderen Spieler (für DropDown oben rechts)
export const query = graphql`
  query OtherManuelPeopleQuery($pid: String, $uid: String) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    people: allTypo3Page(
      filter: { pid: { eq: $pid }, type: { in: "manual_person_page" } }
    ) {
      edges {
        node {
          uid
          full_slug
          data {
            jersey_number
            display_name
          }
        }
      }
    }
  }
`

const ManualPersonPageTemplate = ({ data, pageContext }) => {
  const {
    node,
    website,
    website_lang,
    website_theme,
    tree,
    subnavigation,
    footerPageNodeContent,
  } = pageContext

  const content = JSON.parse(data.page.content)
  const { t } = useTranslation()
  const [isActive, setIsActive] = useState(false)

  let full_slug = clean_fullslug(node.full_slug)

  let people = []
  data.people.edges.forEach(edge => {
    people.push({
      jersey_number: edge.node.data.jersey_number,
      title: edge.node.data.display_name,
      link: edge.node.full_slug,
      active: edge.node.uid === node.uid ? true : false,
    })
  })
  // Nach Rückennummer sortieren
  people = people.sort(function(a, b) {
    return a.jersey_number < b.jersey_number ? -1 : 1
  })

  let breadcrumbs = []

  // DropDown oben rechts
  let subnavigationDropdown1 = []
  subnavigationDropdown1.push({
    link: content.parentdata.full_slug,
    title: t("Dropdowns.AllPlayers"),
    active: false,
  })

  for (let i in people) {
    let p = people[i]
    subnavigationDropdown1.push(p)
  }

  // Shortcut
  let person = node.data

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      content={{ parents: breadcrumbs }}
      subnavigation={subnavigation}
      subnavigationDropdown1={subnavigationDropdown1}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={node.full_slug}
      />
      <main className="ef-content ef-content--player">
        <header className="ef-content__hero">
          <div className="ef-fullscreen-hero ef-fullscreen-hero--player">
            <div className="ef-fullscreen-hero__media">
              <picture>
                <img
                  className="ef-fullscreen-hero__image"
                  src={node.data.hero_image}
                />
              </picture>
              <div className="ef-fullscreen-hero__branding ef-fullscreen-hero__branding--back-1"></div>
              <div className="ef-fullscreen-hero__branding ef-fullscreen-hero__branding--back-2"></div>

              <div className="ef-fullscreen-hero__branding ef-fullscreen-hero__branding--front-1"></div>
              <div className="ef-fullscreen-hero__branding ef-fullscreen-hero__branding--front-2"></div>
            </div>

            <div className="ef-fullscreen-hero__content">
              <div className="ef-fullscreen-hero__title">
                <h1 className="ef-heading ef-heading--1 ef-heading--has-kicker ef-heading--on-image ef-fullscreen-hero__heading">
                  <div className="ef-kicker ef-fullscreen-hero__kicker">
                    {peopleGrouper(person.position)}
                  </div>
                  <div className="ef-fullscreen-hero__heading-text">
                    <div className="ef-fullscreen-hero__player">
                      <div className="ef-fullscreen-hero__player-number">
                        {person.jersey_number}
                      </div>
                      <div className="ef-fullscreen-hero__player-name">
                        {person.display_name}
                      </div>
                    </div>
                  </div>
                </h1>
              </div>
              <div className="ef-fullscreen-hero__text">
                <div className="ef-player-profile">
                  <div className="ef-player-profile__items">
                    {person.country && (
                      <div className="ef-player-profile__item">
                        <strong>{t("Stats.Nationality")}: </strong>
                        <br />
                        {person.country}
                      </div>
                    )}

                    {person.birthday && (
                      <div className="ef-player-profile__item">
                        <strong>{t("Stats.Birthday")}: </strong>
                        <br />
                        <Moment format={DATE_TIME_FORMATS.default}>
                          {person.birthday}
                        </Moment>
                      </div>
                    )}

                    {person.height && (
                      <div className="ef-player-profile__item">
                        <strong>{t("Stats.Height")}: </strong>
                        <br />
                        {person.height}m
                      </div>
                    )}

                    {person.ef_player_since && (
                      <div className="ef-player-profile__item">
                        <strong>A{t("Stats.PlayerSince")}: </strong>
                        <br />
                        <Moment format={DATE_TIME_FORMATS.default}>
                          {person.ef_player_since}
                        </Moment>
                      </div>
                    )}

                    <div className="ef-player-profile__item">
                      <div className="ef-player-profile__social-links">
                        {person.facebook_url && (
                          <ASocialLink
                            url={person.facebook_url}
                            name="facebook"
                          />
                        )}

                        {person.instagram_url && (
                          <>
                            <ALink
                              className="ef-player-profile__social-link"
                              link={person.instagram_url}
                            >
                              <svg className="ef-icon" width={16} height={16}>
                                <use
                                  xlinkHref="#ef-icon--instagram-16"
                                  href="#ef-icon--instagram-16"
                                />
                              </svg>
                            </ALink>
                          </>
                        )}

                        {person.twitter_url && (
                          <>
                            <ALink
                              className="ef-player-profile__social-link"
                              link={person.twitter_url}
                            >
                              <svg className="ef-icon" width={16} height={16}>
                                <use
                                  xlinkHref="#ef-icon--twitter-16"
                                  href="#ef-icon--twitter-16"
                                />
                              </svg>
                            </ALink>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <article className="ef-article ef-article--low-level">
          <TContentBody content={content} />
        </article>
      </main>

      <MShare title={person.display_name} website={website} disabled="0" />
    </OLayout>
  )
}

export default ManualPersonPageTemplate
