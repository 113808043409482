import React from "react"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { DATE_TIME_FORMATS } from "../../utilities/date-time-formats"
import { period } from "../../utilities/utilities"
import { navigate } from "gatsby"

// import ODebug from "gatsby-component-lib/components-legacy/organisms/o-debug"
import ALink from "../atoms/a-link"

const OMatchMatches = props => {
  const { t } = useTranslation()
  const matchesByDay = props.matchesByDay
  const matchDays = props.matchDays ? props.matchDays : []
  const currentMatchDay = props.currentMatchDay ? props.currentMatchDay : false

  const isEintracht = match => {
    return (
      (match.team_home && match.team_home.id === 159) ||
      (match.team_away && match.team_away.id === 159)
    )
  }

  const isLive = match => {
    const mapIsLive = [
      "Live",
      "FirstHalf",
      "SecondHalf",
      "ExtraFirstHalf",
      "ExtraSecondHalf",
      "ShootOut",
    ]
    return mapIsLive.includes(match.period)
  }

  const onMatchdayChange = e => {
    if (e.target.value.length > 0) {
      navigate("/" + e.target.value)
    }
  }

  // Vorheriger- / Nächster Spieltag > nur in current_match_matches.template verfügbar
  let prev_path = null
  let next_path = null
  let current_path = null
  if (currentMatchDay && matchDays && matchDays.length > 0) {
    let prev_matchday = currentMatchDay > 1 ? currentMatchDay - 1 : false
    let next_matchday =
      currentMatchDay === matchDays.length ? false : currentMatchDay + 1
    prev_path = prev_matchday
      ? matchDays.filter(m => m.matchday === prev_matchday)[0].matches_full_slug
      : false
    next_path = next_matchday
      ? matchDays.filter(m => m.matchday === next_matchday)[0].matches_full_slug
      : false
    current_path = currentMatchDay
      ? matchDays.filter(m => m.matchday === currentMatchDay)[0]
          .matches_full_slug
      : false
  }

  return (
    <>
      <header className="ef-content__header">
        <div className="ef-container ef-container--l">
          <div className="ef-heading-and-filters">
            <h1 className="ef-heading ef-heading--2">
              {props.headline && props.headline}
            </h1>
            {matchDays && matchDays.length > 0 && (
              <div className="ef-filters">
                <div className="ef-filter ef-filter--prev-next">
                  {prev_path && (
                    <div className="ef-filter__prev">
                      <ALink link={prev_path}>
                        <svg className="ef-icon" width={16} height={16}>
                          <use
                            xlinkHref="#ef-icon--chevron-left-16"
                            href="#ef-icon--chevron-left-16"
                          />
                        </svg>
                      </ALink>
                    </div>
                  )}
                  <div className="ais-MenuSelect">
                    <select
                      className="ais-MenuSelect-select"
                      onChange={onMatchdayChange}
                    >
                      {matchDays.map(matchDay => (
                        <option
                          className="ais-MenuSelect-option"
                          value={matchDay.matches_full_slug}
                          selected={matchDay.matchday === currentMatchDay}
                        >
                          {matchDay.matchday}. {t("Matchday")}
                        </option>
                      ))}
                    </select>
                  </div>
                  {next_path && (
                    <div className="ef-filter__next">
                      <ALink link={next_path}>
                        <svg className="ef-icon" width={16} height={16}>
                          <use
                            xlinkHref="#ef-icon--chevron-right-16"
                            href="#ef-icon--chevron-right-16"
                          />
                        </svg>
                      </ALink>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </header>

      <section className="ef-content__block ef-content__block--matches">
        <div className="ef-container ef-container--l">
          <div className="ef-matches ef-matches--live">
            {/* START */}
            {Object.values(matchesByDay).map((obj, index) => {
              return (
                <div key={index} className="ef-matches__day">
                  <h6 className="ef-heading ef-heading--6 ef-matches__heading">
                    <Moment format={DATE_TIME_FORMATS.date_w_day}>
                      {obj.matchday}
                    </Moment>
                  </h6>
                  {obj.matches.map(match => {
                    if (!match.team_home || !match.team_away) {
                      console.log("Corrupt Match", match)
                      return
                    }
                    return (
                      <div
                        key={match.id}
                        className={
                          "ef-match ef-match--parallel " +
                          (isLive(match) ? " ef-match--live" : "") +
                          (isEintracht(match) ? " --is-current" : "")
                        }
                      >
                        {current_path && isEintracht(match) && (
                          <ALink
                            className="ef-match__link"
                            link={current_path}
                          ></ALink>
                        )}
                        <div className="ef-match__team-logo ef-match__team-logo--home">
                          <img
                            alt={match.team_home.team_name + "-logo"}
                            className="ef-match-header__team-logo-image"
                            src={
                              (match.team_home.images &&
                                match.team_home.images.medium_square) ||
                              match.team_home.logo.url
                            }
                          />
                        </div>
                        <div
                          className="ef-match__team-name ef-match__team-name--home"
                          data-shortname={match.team_home.team_initials_name}
                        >
                          <span className="ef-match__team-name-text">
                            {match.team_home.team_name}
                          </span>
                        </div>
                        <div className="ef-match__result">
                          {match.team_home_final_score != null
                            ? match.team_home_final_score
                            : "–"}
                          :
                          {match.team_away_final_score != null
                            ? match.team_away_final_score
                            : "–"}
                        </div>
                        <div className="ef-match__team-logo ef-match__team-logo--away">
                          <img
                            alt={match.team_away.team_name + "-logo"}
                            className="ef-match-header__team-logo-image"
                            src={
                              (match.team_away.images &&
                                match.team_away.images.medium_square) ||
                              match.team_away.logo.url
                            }
                          />
                        </div>
                        <div
                          className="ef-match__team-name ef-match__team-name--away"
                          data-shortname={match.team_away.team_initials_name}
                        >
                          <span className="ef-match__team-name-text">
                            {match.team_away.team_name}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default OMatchMatches
