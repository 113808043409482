import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import Blocks from "gatsby-component-lib/components-legacy/_blocks"
import ODebug from "gatsby-component-lib/components-legacy/organisms/o-debug"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"

const ContentPage = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    website_lang,
    subnavigation,
    website_theme,
    footerPageNodeContent,
  } = pageContext
  const { t } = useTranslation()
  let { breadcrumbs } = pageContext

  const content = JSON.parse(data.page.content)

  if (!breadcrumbs) {
    breadcrumbs =
      content && content.parents && content.parents.length
        ? content.parents.reverse()
        : [{ name: t("Websites." + website), full_slug: "/" }]
  }

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      subnavigation={subnavigation}
      breadcrumbs={breadcrumbs}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={full_slug}
      />
      <main className="ef-content">
        <article className="ef-article">
          <div className="ef-article__body">
            {content.body &&
              content.body.map &&
              content.body.map(block =>
                Blocks[block.component]
                  ? React.createElement(Blocks[block.component], {
                      key: block.uid,
                      data: block,
                      pageData: {},
                    })
                  : `Block ${block.component} in ${block.uid} not created yet`
              )}
            <ODebug content={content} jsonName="Content" />
          </div>
        </article>
      </main>
    </OLayout>
  )
}

export default ContentPage

export const query = graphql`
  query($uid: String!) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
  }
`
