import { graphql } from "gatsby"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import Blocks from "gatsby-component-lib/components-legacy/_blocks"
import { useTranslation } from "react-i18next"
import React from "react"

export const query = graphql`
  query StartPageQuery($uid: String!) {
    startPageData: typo3Page(uid: { eq: $uid }) {
      content
    }
    squadImages: allTypo3Page(
      filter: {
        type: { in: ["person_page"] }
        data: { pid: { in: "page-5600-de" } }
      }
    ) {
      edges {
        node {
          data {
            person_uid
            transparent_image
          }
        }
      }
    }
    squad: allTypo3Page(filter: { uid: { eq: "team-159-2019-de" } }) {
      edges {
        node {
          uid
          slug
          full_slug
          type
          name
          data {
            season {
              id
              title
              slug
            }
            players {
              person {
                id
                uid
                title
                slug
                full_slug
                position
                jersey_number
              }
            }
          }
        }
      }
    }
    matches: allTypo3Page(filter: { uid: { eq: "schedule-2019--de" } }) {
      edges {
        node {
          uid
          slug
          full_slug
          type
          name
          data {
            matches {
              full_slug
              id
              kickoff
              kickoff_timezone
              language
              matchday
              matchday_title
              period
              team_away_final_score
              team_home_final_score
              team_home {
                id
                team_name
                team_initials_name
                images {
                  liveticker
                  liveticker_retina
                  medium_square
                  mini_square
                  micro_square
                  original
                }
              }
              team_away {
                id
                team_name
                team_initials_name
                images {
                  liveticker
                  liveticker_retina
                  medium_square
                  micro_square
                  mini_square
                  original
                }
              }
              competition {
                id
                slug
                title
                short_title
              }
            }
            season {
              id
              slug
              title
            }
            competition {
              id
              slug
              title
            }
          }
        }
      }
    }
    standings: allTypo3Page(
      filter: { uid: { eq: "standing-2019-22-current-de" } }
    ) {
      edges {
        node {
          uid
          slug
          full_slug
          type
          name
          data {
            period
            matchday
            match_uid
            competition {
              id
              slug
              title
            }
            season {
              id
              slug
              title
            }
            component
            matchdays {
              has_standings
              matchday
              uid
              full_slug
            }
            standings {
              full {
                drawn
                goal_balance
                goals
                goals_against
                goals_for
                info
                lost
                played
                points
                position
                position_trend
                team_id
                team_name
                team_initials_name
                team_logo_url
                won
                trends {
                  home_team
                  opponent_name
                  team_away_final_score
                  team_home_final_score
                  trend
                }
              }
              home {
                drawn
                goal_balance
                goals
                goals_against
                goals_for
                info
                lost
                played
                points
                position
                position_trend
                team_id
                team_name
                team_initials_name
                team_logo_url
                won
                trends {
                  home_team
                  opponent_name
                  team_away_final_score
                  team_home_final_score
                  trend
                }
              }
              away {
                drawn
                goal_balance
                goals
                goals_against
                goals_for
                info
                lost
                played
                points
                position
                position_trend
                team_id
                team_name
                team_logo_url
                won
                trends {
                  home_team
                  opponent_name
                  team_away_final_score
                  team_home_final_score
                  trend
                }
              }
            }
          }
        }
      }
    }
  }
`

const DefaultPage = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    subnavigation,
    website_lang,
    website_theme,
    footerPageNodeContent,
  } = pageContext
  const { t } = useTranslation()

  let { breadcrumbs } = pageContext

  const content = JSON.parse(data.startPageData.content)
  const metadata = content.metadata

  // const description = metadata ? metadata.description : content.teaser

  if (!breadcrumbs && website !== "global") {
    breadcrumbs =
      content && content.parents && content.parents.length
        ? content.parents.reverse()
        : [{ name: t("Websites." + website), full_slug: "/" }]
  }

  return (
    <>
      <OLayout
        startPageData={data}
        tree={tree}
        website={website}
        website_theme={website_theme}
        website_lang={website_lang}
        subnavigation={subnavigation}
        breadcrumbs={breadcrumbs}
        footerPageNodeContent={footerPageNodeContent}
        showAds={!content.hide_ads}
      >
        <TSeo
          website_theme={website_theme}
          website={website}
          content={content}
          full_slug={full_slug}
        />
        <main className="ef-content">
          <article className="ef-article">
            <div className="ef-article__body">
              {content.body &&
                content.body.map &&
                content.body.map(block =>
                  Blocks[block.component]
                    ? React.createElement(Blocks[block.component], {
                        key: block.uid,
                        data: block,
                        pageData: {},
                      })
                    : `Block ${block.component} in ${block.uid} not created yet`
                )}
            </div>
          </article>
        </main>
        <MShare
          title={metadata ? metadata.title : ""}
          website={website}
          disabled={
            content && content.disable_share_button
              ? content.disable_share_button
              : 0
          }
        />
      </OLayout>
    </>
  )
}

export default DefaultPage
