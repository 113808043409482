/* eslint-disable no-unused-vars */
import { graphql } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"

import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import OSquadByPosition from "gatsby-component-lib/components-legacy/organisms/o-squad-by-position"
import {
  squadSubNavigation,
  clean_fullslug,
  peopleGrouper,
} from "gatsby-component-lib"

export const query = graphql`
  query TeamStaffPageTemplateQuery(
    $team_uid: String
    $website: String
    $uid: String
  ) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }

    squad: allTypo3Page(filter: { uid: { eq: $team_uid } }) {
      edges {
        node {
          uid
          slug
          full_slug
          type
          name
          data {
            season {
              id
              title
              slug
            }
            staff {
              person {
                id
                uid
                title
                slug
                position
                full_slug
              }
            }
          }
        }
      }
    }
    peopleData: allTypo3Page(
      filter: { type: { in: ["person_page"] }, website: { in: [$website] } }
    ) {
      edges {
        node {
          data {
            person_uid
            transparent_image
          }
        }
      }
    }
  }
`
const TeamStaffPageTemplate = ({ data, pageContext }) => {
  const {
    node,
    subnavigation,
    website,
    website_lang,
    website_theme,
    tree,
    footerPageNodeContent,
  } = pageContext

  const { t } = useTranslation()

  let related_data = null
  if (data.squad.edges.length > 0) {
    // related_data = Ergebnis der Sub-Query (oben)
    related_data = data.squad.edges[0].node.data
  }

  let people = []
  let season = false
  if (related_data) {
    // Personen werden zuerst nach der Rückennummer sortiert
    people = related_data.staff
    season = related_data.season
  }

  const content = JSON.parse(data.page.content)

  let peopleData = data.peopleData.edges
  let full_slug = clean_fullslug(node.full_slug)
  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    { name: t("Team"), full_slug: content.parents[0].full_slug },
  ]

  let title =
    content.title + " " + (season ? season.title : "").replace("/", "-")

  // Personen werden nach Bereich (Tor, Abwehr, Mittelfeld, Sturm) gruppiert
  let mappedPeople = {}
  for (let i in people) {
    // PersonData ist die Typo3 Seite des Spielers (wir brauchen das transparente Bild)
    let personData = false
    personData = peopleData.filter(
      edge =>
        edge.node.data !== null &&
        edge.node.data.person_uid === people[i]["person"]["uid"]
    )

    // Gruppierung der Bereiche (sportl. Leitung, Trainer, etc.)
    if (!mappedPeople[peopleGrouper(people[i]["person"]["position"])]) {
      mappedPeople[peopleGrouper(people[i]["person"]["position"])] = []
    }
    // Wenn die Spieler-Typo3 Seite gefunden wurde
    //   => transparent_image in das Objekt mergen
    //   ansonsten: key leer mergen
    if (personData && personData[0] && personData[0].node) {
      mappedPeople[peopleGrouper(people[i]["person"]["position"])].push(
        Object.assign(people[i]["person"], {
          transparent_image: personData[0].node.data.transparent_image,
        })
      )
    } else {
      mappedPeople[peopleGrouper(people[i]["person"]["position"])].push(
        Object.assign(people[i]["person"], {
          transparent_image: "",
        })
      )
    }
  }

  // DropDown oben rechts
  let subnavigationDropdown1 = []
  subnavigationDropdown1.push({
    link: full_slug,
    title: t("Dropdowns.AllOfficials"),
    active: false,
  })
  for (let i in people) {
    let p = people[i]
    subnavigationDropdown1.push({
      link: clean_fullslug(p.person.full_slug),
      title: p.person.title,
      active: false,
    })
  }

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      content={{ parents: breadcrumbs }}
      breadcrumbs={breadcrumbs}
      subnavigation={subnavigation}
      subnavigationDropdown1={subnavigationDropdown1}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={node.full_slug}
        title={title}
      />
      <main className="ef-content ef-content--squad">
        <header className="ef-content__header">
          <div className="ef-container ef-container--l">
            <h1 className="ef-heading ef-heading--2">
              {content.headline} {season ? season.title : ""}
            </h1>
          </div>
        </header>

        {Object.keys(mappedPeople).map(key => (
          <OSquadByPosition
            key={key}
            people={mappedPeople[key]}
            position={key}
          />
        ))}
      </main>
      <MShare
        title={content.metadata ? content.metadata.title : content.title}
        website={website}
        disabled={
          content && content.disable_share_button
            ? content.disable_share_button
            : 0
        }
      />
    </OLayout>
  )
}

export default TeamStaffPageTemplate
