import OCustomPagination from "gatsby-component-lib/components-legacy/organisms/o-custom-pagination"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import OTVBanner from "gatsby-component-lib/components-legacy/organisms/o-tv-banner"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import React, { useState } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import { useCookies } from "react-cookie"
import { OVideoBlockHOC } from "./video_page-template"

const VideoOverviewPage = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    website_lang,
    website_theme,
    numPages,
    currentPage,
    videoList,
    subnavigation,
    footerPageNodeContent,
  } = pageContext

  const { t } = useTranslation()

  let { breadcrumbs } = pageContext

  const content = JSON.parse(data.page.content)
  const metadata = content.metadata

  const description = metadata ? metadata.description : content.teaser

  if (!breadcrumbs) {
    breadcrumbs =
      content && content.parents && content.parents.length
        ? content.parents.reverse()
        : [{ name: t("Websites." + website), full_slug: "/" }]
  }

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage === 2 ? full_slug : `${full_slug}${currentPage - 1}`
  const nextPage = `${full_slug}${currentPage + 1}`
  const needPagination = numPages > 1

  const [cookies] = useCookies(["yum_jwt"])
  const [authenticated] = useState(cookies && cookies.yum_jwt)

  return (
    <OLayout
      tree={tree}
      website={website}
      subnavigation={subnavigation}
      breadcrumbs={breadcrumbs}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        title={content.metadata.title}
        description={description}
        full_slug={full_slug}
        og_title={content.metadata.ogtitle}
        og_image={content.metadata.ogimage.src}
        og_image_width="780"
        og_image_height="450"
        og_locale="de_DE"
        twitter_card="summary"
        twitter_title={content.metadata.title}
        website_theme={website_theme}
      />
      <main className="ef-content ef-content--tv-category">
        <header className="ef-content__header">
          <div className="ef-container ef-container--l">
            <h1 className="ef-heading ef-heading--2">{content.headline}</h1>
          </div>
        </header>
        <OVideoBlockHOC blockType="grid" videos={videoList} />
        <section className="ef-content__block ef-content__block--pagination">
          {needPagination && (
            <OCustomPagination
              currentPage={currentPage}
              numPages={numPages}
              isFirst={isFirst}
              isLast={isLast}
              prevPage={prevPage}
              nextPage={nextPage}
              currentPath={full_slug}
            />
          )}
        </section>
        <OTVBanner authenticated={authenticated} />
      </main>
    </OLayout>
  )
}

export default VideoOverviewPage

export const query = graphql`
  query($uid: String!) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
  }
`
