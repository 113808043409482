import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { OMatchHeader, period } from "gatsby-component-lib"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import OLayoutMatch from "gatsby-component-lib/components-legacy/organisms/o-layout_match"
import OMatchMatches from "gatsby-component-lib/components-legacy/organisms/o-match-matches"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import { LiveMatchContext } from "gatsby-component-lib/context/LiveMatchContext"
import moment from "moment"
import React, { useContext } from "react"

export const query = graphql`
  query MatchMatchesQuery(
    $league_table_uid: String
    $match_uid: String
    $uid: String
  ) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    match: allTypo3Page(
      filter: { uid: { eq: $match_uid }, type: { in: "match" } }
    ) {
      nodes {
        id
        page_uid
        name
        type
        website
        full_slug
        title
        data {
          liveticker_active
          attendance
          city
          definite
          id
          matchday
          matchday_title
          referees {
            last_name
            first_name
            role
            birthday
          }
          team_away {
            id
            team_name
            team_initials_name
            formation_used
            images {
              liveticker
              liveticker_retina
              medium_square
              micro_square
              mini_square
              original
            }
          }
          team_home {
            id
            team_name
            team_initials_name
            formation_used
            images {
              liveticker
              liveticker_retina
              medium_square
              micro_square
              mini_square
              original
            }
          }
          team_away_half_score
          team_away_ninety_score
          team_away_penalty_score
          team_away_extra_score
          team_away_final_score
          team_home_half_score
          team_home_ninety_score
          team_home_penalty_score
          team_home_extra_score
          team_home_final_score
          venue
          match_uid
          competition {
            id
            slug
            title
          }
          season {
            id
            slug
            title
          }
          attendance
          kickoff
          kickoff_timezone
          period
          opta_events {
            id
            assist_person_id
            assist_person_name
            event_type
            person_id
            period
            person_name
            person_name_off
            person_name_on
            person_off_id
            person_on_id
            team
            team_name
            time
          }
          ticker_events {
            phase
            event_type
            time
            time_additional
            description
            html_description
            image_src
          }
        }
      }
    }
    leagueTable: allTypo3Page(
      filter: { uid: { eq: $league_table_uid }, type: { in: "standing" } }
    ) {
      nodes {
        uid
        id
        page_uid
        name
        type
        website
        full_slug
        standing_type
        data {
          period
          matchday
          matchday_title
          match_uid
          competition {
            id
            slug
            title
          }
          season {
            id
            slug
            title
          }
          component
          matchdays {
            has_standings
            matchday
            uid
            full_slug
          }
          standings {
            full {
              drawn
              goal_balance
              goals
              goals_against
              goals_for
              info
              lost
              played
              points
              position
              position_trend
              team_id
              team_name
              team_initials_name
              team_logo_url
              won
              trends {
                home_team
                opponent_name
                team_away_final_score
                team_home_final_score
                trend
              }
            }
            home {
              drawn
              goal_balance
              goals
              goals_against
              goals_for
              info
              lost
              played
              points
              position
              position_trend
              team_id
              team_name
              team_initials_name
              team_logo_url
              won
              trends {
                home_team
                opponent_name
                team_away_final_score
                team_home_final_score
                trend
              }
            }
            away {
              drawn
              goal_balance
              goals
              goals_against
              goals_for
              info
              lost
              played
              points
              position
              position_trend
              team_id
              team_name
              team_logo_url
              won
              trends {
                home_team
                opponent_name
                team_away_final_score
                team_home_final_score
                trend
              }
            }
          }
          matches {
            id
            period
            kickoff
            kickoff_timezone
            team_away {
              id
              team_name
              team_initials_name
              images {
                liveticker
                liveticker_retina
                medium_square
                micro_square
                mini_square
                original
              }
            }
            team_home {
              id
              team_name
              team_initials_name
              images {
                liveticker
                liveticker_retina
                medium_square
                micro_square
                mini_square
                original
              }
            }
            team_away_final_score
            team_away_half_score
            team_home_final_score
            team_home_half_score
          }
        }
      }
    }
  }
`

const MatchMatches = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    website_lang,
    website_theme,
    subnavigation,
  } = pageContext
  const { t } = useTranslation()

  const content = JSON.parse(data.page.content)
  const leagueTableNode = data.leagueTable.nodes
    ? data.leagueTable.nodes[0]
    : []
  const leagueTableData = leagueTableNode ? leagueTableNode.data : null

  const matchNode = data.match.nodes ? data.match.nodes[0] : {}
  let matchData = matchNode ? matchNode.data : {}

  let matches =
    leagueTableData && leagueTableData.matches.length > 0
      ? leagueTableData.matches
      : []

  const matchContext = useContext(LiveMatchContext)

  if (
    matchContext &&
    matchContext.liveMatchData &&
    matchContext.liveMatchData.liveticker_active &&
    parseInt(matchData.id) === parseInt(matchContext.matchId)
  ) {
    matchData = matchContext.liveMatchData
    matches = matchContext.liveMatchData.matches || []
  }

  let matchesByDay = {}
  matches.forEach(match => {
    const kickoffDay = moment(match.kickoff)
    if (!matchesByDay[kickoffDay.isoWeekday()]) {
      matchesByDay[kickoffDay.isoWeekday()] = {
        matchday: moment(match.kickoff),
        matches: [match],
      }
    } else {
      const obj = matchesByDay[kickoffDay.isoWeekday()]
      obj.matches.push(match)
    }
  })

  const second_breadcrumb = ["profis", "en"].includes(website)
    ? {
        name: t("Season"),
        full_slug: "/" + t("SeasonSlug"),
      }
    : content.parents.reverse()[1]

  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    second_breadcrumb,
  ]

  let title =
    content.title +
    " " +
    content.parents[0].name +
    " " +
    content.parents[2].name +
    " " +
    content.parents[1].name

  return (
    <OLayoutMatch
      tree={tree}
      website={website}
      website_lang={website_lang}
      subnavigation={subnavigation}
      liveMatchData={matchData}
      breadcrumbs={breadcrumbs}
      // subnavigationDropdown2={Object.values(seasonNavigation)}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={full_slug}
        title={title}
      />
      <main
        className={
          "ef-content ef-content--match-matches " +
          (matchData.period === period.FULL_TIME ? " --is-fulltime" : "") +
          ((matchContext
          ? matchContext.matchIsLive
          : matchData.liveticker_active)
            ? " --is-live"
            : "")
        }
      >
        <div className="ef-match-meta" data-theme="light">
          <OMatchHeader matchData={matchData} website={website} />
        </div>
        <OMatchMatches
          headline={content.headline}
          matchesByDay={matchesByDay}
        />
      </main>
      {leagueTableNode && (
        <MShare
          title={leagueTableNode.name}
          website={website}
          disabled={
            leagueTableNode && leagueTableNode.disable_share_button
              ? leagueTableNode.disable_share_button
              : 0
          }
        />
      )}
    </OLayoutMatch>
  )
}

export default MatchMatches
