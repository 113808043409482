import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import OFullscreenHeroBlock from "gatsby-component-lib/components-legacy/organisms/o-fullscreen-hero-block"
import Blocks from "gatsby-component-lib/components-legacy/_blocks"

const HeroPage = ({ data, pageContext }) => {
  const {
    node,
    tree,
    subnavigation,
    website,
    website_lang,
    website_theme,
    footerPageNodeContent,
  } = pageContext
  const { t } = useTranslation()
  let { breadcrumbs } = pageContext

  const content = JSON.parse(data.typo3Page.content)

  if (!breadcrumbs) {
    breadcrumbs =
      content && content.parents && content.parents.length
        ? content.parents.reverse()
        : [{ name: t("Websites." + website), full_slug: "/" }]
  }

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      subnavigation={subnavigation}
      breadcrumbs={breadcrumbs}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={node.full_slug}
      />
      <main className="ef-content">
        <article className="ef-article">
          {content.images && content.images.length > 0 && (
            <OFullscreenHeroBlock
              data={{
                kicker: content.kicker,
                headline: content.headline,
                intro: content.subline,
                image: content.images[0],
              }}
            />
          )}
          {content.images === undefined && (
            <OFullscreenHeroBlock
              data={{
                kicker: content.kicker,
                headline: content.headline,
                intro: content.subline,
              }}
            />
          )}
          <div className="ef-article__body">
            {content.body &&
              content.body.map &&
              content.body.map(bodyBlock => {
                // eslint-disable-next-line no-extra-boolean-cast
                return !!Blocks[bodyBlock.component]
                  ? React.createElement(Blocks[bodyBlock.component], {
                      key: bodyBlock.uid,
                      data: bodyBlock,
                      pageData: {},
                    })
                  : `Block ${bodyBlock.component} in ${bodyBlock.uid} not created yet`
              })}
          </div>
        </article>
      </main>
    </OLayout>
  )
}

export const query = graphql`
  query($uid: String!) {
    typo3Page(uid: { eq: $uid }) {
      content
    }
  }
`

export default HeroPage
