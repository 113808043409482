import { graphql } from "gatsby"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import OMatchMatches from "gatsby-component-lib/components-legacy/organisms/o-match-matches"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import React from "react"
import { useTranslation } from "react-i18next"

import moment from "moment"

// import ODebug from "gatsby-component-lib/components-legacy/organisms/o-debug"

export const query = graphql`
  query CurrentMatchesQuery($league_table_uid: String, $uid: String) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    allTypo3Page(
      filter: { uid: { eq: $league_table_uid }, type: { in: "standing" } }
    ) {
      nodes {
        uid
        id
        page_uid
        name
        type
        website
        full_slug
        standing_type
        data {
          matchday
          match_uid
          competition {
            id
            slug
            title
          }
          season {
            id
            slug
            title
          }
          component
          matchdays {
            has_standings
            matchday
            uid
            full_slug
          }
          matches {
            kickoff
            period
            team_home_final_score
            team_away_final_score
            team_home {
              id
              team_name
              team_initials_name
              images {
                micro_square
                medium_square
              }
            }
            team_away {
              id
              team_name
              team_initials_name
              images {
                micro_square
                medium_square
              }
            }
          }
          matchdays {
            matchday
            matches_full_slug
          }
        }
      }
    }
  }
`

const CurrentMatchesPageTemplate = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    website_lang,
    website_theme,
    subnavigation,
  } = pageContext
  const { t } = useTranslation()
  const content = JSON.parse(data.page.content)

  const second_breadcrumb = ["profis", "en"].includes(website)
    ? {
        name: t("Season"),
        full_slug: "/" + t("SeasonSlug"),
      }
    : content.parents.reverse()[1]

  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    second_breadcrumb,
  ]

  let matches = false
  matches =
    data.allTypo3Page.nodes &&
    data.allTypo3Page.nodes[0] &&
    data.allTypo3Page.nodes[0].data
      ? data.allTypo3Page.nodes[0].data.matches
      : []

  let matchDays = []
  matchDays =
    data.allTypo3Page.nodes &&
    data.allTypo3Page.nodes[0] &&
    data.allTypo3Page.nodes[0].data
      ? data.allTypo3Page.nodes[0].data.matchdays
      : []

  let currentMatchDay = false
  currentMatchDay =
    data.allTypo3Page.nodes &&
    data.allTypo3Page.nodes[0] &&
    data.allTypo3Page.nodes[0].data
      ? data.allTypo3Page.nodes[0].data.matchday
      : false

  let matchesByDay = {}
  matches.forEach(match => {
    const kickoffDay = moment(match.kickoff)
    if (!matchesByDay[kickoffDay.isoWeekday()]) {
      matchesByDay[kickoffDay.isoWeekday()] = {
        matchday: moment(match.kickoff),
        matches: [match],
      }
    } else {
      const obj = matchesByDay[kickoffDay.isoWeekday()]
      obj.matches.push(match)
    }
  })

  let title =
    currentMatchDay +
    ". " +
    t("Stats.Matchday") +
    " " +
    content.parents[1].name +
    " " +
    content.parents[0].name

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      subnavigation={subnavigation}
      breadcrumbs={breadcrumbs}
      website_theme={website_theme}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={full_slug}
        title={title}
      />

      <main className="ef-content ef-content--match-league-table">
        <OMatchMatches
          headline={content.headline}
          matchesByDay={matchesByDay}
          matchDays={matchDays}
          currentMatchDay={currentMatchDay}
        />
      </main>
    </OLayout>
  )
}

export default CurrentMatchesPageTemplate
