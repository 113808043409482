import React from "react"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"

import OGallerySliderBlock from "gatsby-component-lib/components-legacy/organisms/o-gallery-slider-block"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"

// import ODebug from "gatsby-component-lib/components-legacy/organisms/o-debug"

const GalleryDetailTemplate = ({ data, pageContext }) => {
  const {
    node,
    full_slug,
    tree,
    website,
    website_lang,
    website_theme,
    footerPageNodeContent,
  } = pageContext
  const content = JSON.parse(data.typo3Page.content)

  const { t } = useTranslation()

  let content_images = []
  if (content.images && content.images.length > 1) {
    for (let i = 0; i < content.images.length; i++) {
      let current_image = content.images[i]
      content_images.push(current_image)
    }
  }

  // console.log(content_images)

  let og_url =
    "https://www.eintracht.de/de/bildergalerien/bildergalerien/" + node.slug

  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    {
      name: t("LatestNews"),
      full_slug: "/" + t("LatestNewsSlug"),
    },
  ]

  return (
    <OLayout
      tree={tree}
      website={website}
      breadcrumbs={breadcrumbs}
      // subnavigation={subnavigation}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={full_slug}
        og_url={og_url}
        og_image={content_images[0] && content_images[0].src}
        og_image_width="780"
        og_image_height="450"
      />

      <main className="ef-content ef-content--gallery">
        <header className="ef-content__header">
          <div className="ef-container ef-container--l">
            <h1 className="ef-heading ef-heading--2">{content.headline}</h1>
          </div>
        </header>

        <article className="ef-article">
          <section className="ef-article__body">
            {content_images && content_images.length > 1 && (
              <OGallerySliderBlock
                data={{
                  images: content_images,
                }}
              />
            )}
          </section>
        </article>
      </main>
      <MShare
        title={content.metadata.title}
        website={website}
        disabled={
          content && content.disable_share_button
            ? content.disable_share_button
            : 0
        }
      />
    </OLayout>
  )
}

export const query = graphql`
  query($uid: String!) {
    typo3Page(uid: { eq: $uid }) {
      content
    }
  }
`

export default GalleryDetailTemplate
