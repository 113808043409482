import { graphql } from "gatsby"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import { DATE_TIME_FORMATS } from "gatsby-component-lib/utilities/date-time-formats"
import { clean_fullslug } from "gatsby-component-lib"

import ALink from "../../../../../gatsby-component-lib/components-legacy/atoms/a-link"

let eintracht_team_id = 159

export const query = graphql`
  query EventsOverviewQuery(
    $season_uid: String
    $website: String
    $uid: String
  ) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    schedules: allTypo3Page(
      filter: { uid: { eq: $season_uid }, type: { in: "schedule" } }
    ) {
      edges {
        node {
          website
          data {
            matches {
              full_slug
              kickoff
              kickoff_timezone
              definite
              matchday
              team_away_final_score
              team_home_final_score
              team_home {
                id
                team_name
                team_initials_name
              }
              team_away {
                id
                team_name
                team_initials_name
              }
              competition {
                title
              }
            }
          }
        }
      }
    }
    events: allTypo3Page(
      filter: { type: { in: "event_page" }, website: { in: [$website] } }
    ) {
      edges {
        node {
          slug
          content
        }
      }
    }
  }
`

const EventsOverviewPage = ({ data, pageContext }) => {
  const {
    node,
    tree,
    website,
    website_lang,
    website_theme,
    footerPageNodeContent,
  } = pageContext
  const content = JSON.parse(data.page.content)

  const { t } = useTranslation()
  let breadcrumbs = content && content.parents ? content.parents.reverse() : []

  /* liefert die Kalender-Woche eines Date-Objektes */
  Date.prototype.getWeek = function() {
    var date = new Date(this.getTime())
    date.setHours(0, 0, 0, 0)
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
    var week1 = new Date(date.getFullYear(), 0, 4)
    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    )
  }
  /* Distinct Helper für Array */
  const distinct = (value, index, self) => {
    return self.indexOf(value) === index
  }

  let events = data.events.edges
  let matches = false
  if (
    data.schedules.edges[0] &&
    data.schedules.edges[0].node &&
    data.schedules.edges[0].node.data &&
    data.schedules.edges[0].node.data.matches
  ) {
    matches = data.schedules.edges[0].node.data.matches
  }

  // Hier werden die Kalender-Wochen gehalten, zB.: ['2019-51', '2019-52', '2020-01', ... ]
  let mappedWeeks = []
  // Hier werden die Termine gehalten (zusätzlich "year", "week" und "type" für die spätere Filterung + "date" für die Sortierung)
  let mappedEvents = []

  events.forEach(edge => {
    let content = JSON.parse(edge.node.content)

    let d = new Date(content["event_start_date"])
    let year = d.getFullYear()
    let week = d.getWeek()

    let prefixed_week
    if (week < 10) {
      prefixed_week = "0" + week
    } else {
      prefixed_week = week
    }

    // Termin gültig?
    if (year && week && d > new Date()) {
      let s = year + "-" + prefixed_week
      mappedWeeks.push(s)
      mappedEvents.push(
        Object.assign(content, {
          year: year,
          week: week,
          type: "event",
          date: moment(content.event_start_date).format("YYYY-MM-DD"),
          ttitle: content.event_alternative_datetime_text
            ? content.event_alternative_datetime_text
            : content.title,
          full_slug: node.full_slug + edge.node.slug,
        })
      )
    }
  })

  // Eintracht-Spiele
  if (matches) {
    matches.forEach(match => {
      if (match.kickoff) {
        let d = new Date(match.kickoff)
        let year = d.getFullYear()
        let week = d.getWeek()

        let prefixed_week
        if (week < 10) {
          prefixed_week = "0" + week
        } else {
          prefixed_week = week
        }

        // Termin gültig?
        if (year && week && d >= new Date()) {
          let s = year + "-" + prefixed_week
          mappedWeeks.push(s)
          mappedEvents.push(
            Object.assign(match, {
              year: year,
              week: week,
              type: "match",
              opponent:
                match.team_home.id === eintracht_team_id
                  ? match.team_away
                  : match.team_home,
              home_game:
                match.team_home.id === eintracht_team_id ? true : false,
              date: moment(match.kickoff).format("YYYY-MM-DD"),
              full_slug: match.full_slug,
            })
          )
        }
      }
    })
  }

  // Hier halten wir die Daten pro Kalender-Woche
  let mappedData = []
  for (const [i, w] of mappedWeeks
    .filter(distinct)
    .sort()
    .entries()) {
    let x = w.split("-")
    let year = parseInt(x[0])
    let week = parseInt(x[1])

    let filtered_events = mappedEvents
      .filter(
        e =>
          Math.ceil(e.year) === Math.ceil(year) &&
          Math.ceil(e.week) === Math.ceil(week)
      )
      .sort((a, b) => (a.date > b.date ? 1 : -1))

    mappedData.push({
      year: year,
      week: week,
      index: i,
      events: filtered_events,
    })
  }

  let current_year = 0

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      breadcrumbs={breadcrumbs}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={node.full_slug}
      />
      <main className="ef-content ef-content--dates">
        <header className="ef-content__header">
          <div className="ef-container ef-container--l">
            <div className="ef-content__header-with-filters">
              <h1 className="ef-heading ef-heading--2">{content.headline}</h1>
            </div>
          </div>
        </header>

        <section className="ef-content__block ef-content__block--data-table">
          <div className="ef-container ef-container--l">
            {mappedData &&
              mappedData.map((e, index) => (
                <React.Fragment key={"events-week-" + index}>
                  {current_year !== e.year && (
                    <h3 className="ef-heading ef-heading--3">
                      {(current_year = e.year)}
                    </h3>
                  )}

                  <div className="ef-dates-week">
                    <div className="ef-data-table ef-dates --has-links">
                      <div className="ef-data-table__body">
                        {e.events &&
                          e.events.map((event, i) => (
                            <React.Fragment key={"event-" + index + "-" + i}>
                              {event.type === "event" && (
                                <div className="ef-data-table__row">
                                  <div className="ef-data-table__cell ef-dates__cell ef-dates__cell--date">
                                    <div className="ef-dates__date">
                                      <Moment
                                        format={DATE_TIME_FORMATS.date_w_day}
                                      >
                                        {event.event_start_date}
                                      </Moment>
                                      <br />
                                      {event.event_end_date &&
                                        event.event_end_date !== "" && (
                                          <Moment
                                            format={
                                              DATE_TIME_FORMATS.date_w_day
                                            }
                                          >
                                            {event.event_end_date}
                                          </Moment>
                                        )}
                                    </div>
                                  </div>
                                  <div className="ef-data-table__cell ef-dates__cell ef-dates__cell--description">
                                    {event.teaser !== "" && (
                                      <strong>{event.ttitle}</strong>
                                    )}
                                    {event.teaser === "" && event.ttitle}
                                    <br />
                                    {event.teaser !== "" &&
                                      event.teaser.substring(0, 50) + " ..."}
                                  </div>
                                  <div className="ef-data-table__cell ef-dates__cell ef-dates__cell--location">
                                    {event.location}
                                  </div>
                                  <div className="ef-data-table__cell ef-dates__cell ef-dates__cell--type">
                                    {event.root_categories &&
                                      event.root_categories[0].title}
                                    {event.text !== "" && (
                                      <ALink link={event.full_slug}>
                                        {t("Misc.Details")}
                                      </ALink>
                                    )}
                                  </div>
                                </div>
                              )}

                              {event.type === "match" && (
                                <ALink
                                  className="ef-data-table__row ef-data-table__row--link ef-data-table__row--highlight"
                                  link={clean_fullslug(event.full_slug)}
                                >
                                  <div className="ef-data-table__cell ef-dates__cell ef-dates__cell--date">
                                    <div className="ef-dates__date">
                                      <Moment
                                        format={DATE_TIME_FORMATS.date_w_day}
                                      >
                                        {event.kickoff}
                                      </Moment>
                                    </div>
                                    <div className="ef-dates__time">
                                      {event.definite && (
                                        <Moment format={DATE_TIME_FORMATS.time}>
                                          {event.kickoff}
                                        </Moment>
                                      )}
                                    </div>
                                  </div>

                                  <div className="ef-data-table__cell ef-dates__cell ef-dates__cell--description">
                                    <div className="ef-dates__match">
                                      <div className="ef-dates__match-info">
                                        {event.competition.title}
                                        &nbsp;/&nbsp;
                                        {event.matchday}. {t("Stats.Matchday")}
                                      </div>
                                      <div className="ef-dates__match-team">
                                        {event.opponent.team_name}
                                        &nbsp;
                                        {event.home_game &&
                                          t("Stats.HomeTeamShort")}
                                        {!event.home_game &&
                                          t("Stats.AwayTeamShort")}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="ef-data-table__cell ef-dates__cell ef-dates__cell--location">
                                    {event.venue}
                                  </div>

                                  <div className="ef-data-table__cell ef-dates__cell ef-dates__cell--type">
                                    {event.competition.title}
                                  </div>
                                </ALink>
                              )}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </section>
      </main>
      <MShare title="Termine" website={website} disabled="0" />
    </OLayout>
  )
}

export default EventsOverviewPage
