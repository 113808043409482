import React from "react"
import AImage from "../atoms/a-image"

const OArticleHeader = props => {
  return (
    <header className="ef-article__header">
      <div className="ef-container ef-container--l">
        <div className="ef-article__header-title">
          {props.data.kicker && (
            <div className="ef-kicker">{props.data.kicker}</div>
          )}

          {props.data.headline && (
            <h1
              className={
                "ef-heading ef-heading--1 " +
                (props.data.kicker ? "ef-heading--has-kicker" : "")
              }
            >
              {props.data.headline}
            </h1>
          )}
          {props.data.subheadline && (
            <h3 className="ef-heading ef-heading--3 ef-heading--sub">
              {props.data.subheadline}
            </h3>
          )}
        </div>
        {props.data.image && (
          <div className="ef-article__header-media">
            <div className="ef-media ef-media--16x9">
              <AImage image={props.data.image} image_size="xl" />
            </div>
          </div>
        )}
        <div className="ef-article__header-intro">
          <div className="ef-container__narrow-m">
            <p className="ef-paragraph ef-paragraph--lead">
              {props.data.intro}
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}

OArticleHeader.defaultProps = {
  data: {
    headlineType: "h1",
  },
}

export default OArticleHeader
